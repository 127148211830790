import React from 'react';
import {ViewPrivateUploadButton} from '../../Components/ViewPrivateUploadModal';
import TwpaaUserProfileRevisionButton from '../../Components/TwpaaUserProfileRevisionButton';
import TwpaaUserStudyHoursButton from '../../Components/TwpaaUserStudyHoursButton';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import {getDisableLabels} from '../../Components/TwpaaCourseItem';
import {getOutlet} from 'reconnect.js';

export function renderCustomAdminSection(props) {
  const {name, type, context} = props;
  return [false, null];
}

export function renderCustomAdminCol(props) {
  const {col, record} = props;
  if (col.customType === 'pending_registration-cert') {
    return [
      true,
      <div style={{display: 'flex', gap: 10}}>
        <ViewPrivateUploadButton s3Key={record.cert} title="查看證書" />
        <Ant.Button
          disabled={record.status === 'approve'}
          onClick={async () => {
            if (record.member_id) {
              window.alert('Not impl');
              return;
            }

            if (window.confirm('確認要為新使用者創建帳號嗎?')) {
              AppActions.setLoading(true);
              try {
                await AppActions.twpaaApprovePendingRegistration({
                  id: record.id,
                });
                Ant.message.success('成功！頁面將重新整理，請稍候');
                window.location.reload();
              } catch (ex) {
                console.warn('REV_DBG', ex);
                Ant.message.success('API Error');
              }
              AppActions.setLoading(false);
            }
          }}>
          創建帳號
        </Ant.Button>
      </div>,
    ];
  } else if (col.customType === 'pending_registration-status') {
    return [true, <div>{record.status === 'approve' ? '核准' : '審核中'}</div>];
  } else if (col.customType === 'twpaa_user-actions') {
    return [
      true,
      <div style={{display: 'flex', gap: 5, flexWrap: 'wrap'}}>
        <TwpaaUserProfileRevisionButton size="small" owner={record.owner} />

        <Ant.Button
          size="small"
          onClick={async () => {
            window.open(
              `/admin/event_registrations/?ext_res_def=${encodeURIComponent(
                JSON.stringify({
                  name:
                    '報名課程紀錄 / ' +
                    ('0000' + record.member_id).slice(-4) +
                    ' ' +
                    record.name,
                  predefinedQueries: [{owner: record.owner}],
                  renderCreateButton: null,
                }),
              )}`,
            );
          }}>
          報名課程
        </Ant.Button>

        <TwpaaUserStudyHoursButton
          record={record}
          title="進修時數"
          size="small"
        />

        <Ant.Button
          size="small"
          onClick={async () => {
            window.open(
              `/admin/member_fees/?ext_res_def=${encodeURIComponent(
                JSON.stringify({
                  name:
                    '會費繳納紀錄 / ' +
                    ('0000' + record.member_id).slice(-4) +
                    ' ' +
                    record.name,
                  predefinedQueries: [{id_card_number: record.id_card_number}],
                  renderCreateButton: null,
                }),
              )}`,
            );
          }}>
          會費繳納
        </Ant.Button>

        <Ant.Button
          size="small"
          onClick={async () => {
            window.open(
              `/admin/committee_members/?ext_res_def=${encodeURIComponent(
                JSON.stringify({
                  name:
                    '委員會紀錄 / ' +
                    ('0000' + record.member_id).slice(-4) +
                    ' ' +
                    record.name,
                  predefinedQueries: [{member_id: record.member_id}],
                  renderCreateButton: null,
                }),
              )}`,
            );
          }}>
          委員會
        </Ant.Button>

        <Ant.Button
          size="small"
          onClick={async () => {
            window.open(
              `/admin/supervisor_members/?ext_res_def=${encodeURIComponent(
                JSON.stringify({
                  name:
                    '理監事紀錄 / ' +
                    ('0000' + record.member_id).slice(-4) +
                    ' ' +
                    record.name,
                  predefinedQueries: [{member_id: record.member_id}],
                  renderCreateButton: null,
                }),
              )}`,
            );
          }}>
          理監事
        </Ant.Button>

        <Ant.Button
          size="small"
          type="danger"
          onClick={async () => {
            if (window.confirm('此動作無法還原，確定要繼續嗎?')) {
              const password = window.prompt('請輸入新密碼');
              if (!password) {
                return;
              } else if (password.length < 6) {
                window.alert('密碼應大於6字元');
                return;
              }

              AppActions.setLoading(true);
              try {
                await AppActions.twpaaAdminForceResetUserPassword({
                  owner: record.owner,
                  password,
                });
                Ant.message.success('成功');
              } catch (ex) {
                console.warn('REV_DBG', ex);
                Ant.message.warn('API Error');
              }
              AppActions.setLoading(false);
            }
          }}>
          重設密碼
        </Ant.Button>

        {/*
        <Ant.Button
          type="danger"
          size="small"
          onClick={async () => {
            if (window.confirm('此動作無法還原，確定要繼續嗎?')) {
              AppActions.setLoading(true);
              try {
                await AppActions.twpaaDeleteUser(record.owner);
                Ant.message.success('成功！頁面將重新整理，請稍候');
                window.location.reload();
              } catch (ex) {
                console.warn('REV_DBG', ex);
                Ant.message.success('API Error');
              }
              AppActions.setLoading(false);
            }
          }}>
          刪除
        </Ant.Button>

         */}
      </div>,
    ];
  } else if (col.customType === 'twpaa_member_id') {
    const idWithPadding = ('0000' + record?.member_id).slice(-4);
    const link = `/admin/users/?action=detail&id=${record.id}`;
    return [
      true,
      <Ant.Button
        type="link"
        size="small"
        href={link}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          AppActions.navigate(link);
        }}>
        {idWithPadding}
      </Ant.Button>,
    ];
  } else if (col.customType === 'twpaa_user-status') {
    const labels = {
      pending: '申請中',
      reviewing: '審核中',
      valid: '有效',
      invalid: '停權',
      withdraw_reviewing: '退會申請中',
      withdraw: '退會',
    };
    return [true, <div>{labels[record.status]}</div>];
  } else if (col.customType === 'twpaa-evt_reg-actions') {
    return [
      true,
      <div style={{display: 'flex', gap: 10}}>
        <Ant.Button
          onClick={() => {
            AppActions.navigate(
              `/admin/event_registrations/?action=detail&id=${record.id}`,
            );
          }}>
          編輯
        </Ant.Button>
      </div>,
    ];
  } else if (col.customType === 'twpaa-evt_reg-event') {
    return [
      true,
      <div>
        <h3>{record.product?.name}</h3>
        <div style={{color: '#888'}}>{record.product?.date}</div>
      </div>,
    ];
  } else if (col.customType === 'twpaa-evt_reg-user') {
    return [
      true,
      <div>
        {record.owner ? (
          <div>[會員] {record.name}</div>
        ) : (
          <div>[非會員] {record.name}</div>
        )}
        <div>{record.phone}</div>
        <div>{record.email}</div>
      </div>,
    ];
  } else if (col.customType === 'twpaa-evt_reg-status') {
    const StatusDisplay = {
      success: '報名成功',
      failure: '取消報名',
    };
    return [true, StatusDisplay[record.status] || '報名中'];
  } else if (col.customType === 'twpaa-owner-id_card_number') {
    const user = getOutlet('allUsers')
      .getValue()
      .find((it) => it.owner === record.owner);
    return [true, user?.id_card_number || '---'];
  } else if (col.customType === 'twpaa-owner-member_id') {
    const user = getOutlet('allUsers')
      .getValue()
      .find((it) => it.owner === record.owner);
    return [true, user?.member_id || '---'];
  } else if (col.customType === 'twpaa-owner-name') {
    const user = getOutlet('allUsers')
      .getValue()
      .find((it) => it.owner === record.owner);
    return [true, user?.name || '---'];
  } else if (col.customType === 'twpaa-owner-phone') {
    const user = getOutlet('allUsers')
      .getValue()
      .find((it) => it.owner === record.owner);
    return [true, user?.phone || '---'];
  } else if (col.customType === 'twpaa-owner-email') {
    const user = getOutlet('allUsers')
      .getValue()
      .find((it) => it.owner === record.owner);
    return [true, user?.email || '---'];
  } else if (col.customType === 'twpaa-supervisor_member-id_card_number') {
    return [
      true,
      <Ant.Button
        type="link"
        onClick={async () => {
          window.open(
            `/admin/users/?ext_res_def=${encodeURIComponent(
              JSON.stringify({
                predefinedQueries: [{id_card_number: record.id_card_number}],
              }),
            )}`,
          );
        }}>
        {record.id_card_number}
      </Ant.Button>,
    ];
  } else if (col.customType === 'twpaa-product-evt_regs') {
    const disableLabels = getDisableLabels(record);
    return [
      true,
      <div style={{width: 100}}>
        {disableLabels.length > 0 && (
          <ul style={{padding: 0, margin: 0}}>
            {disableLabels.map((it) => {
              return <li key={it}>{it}</li>;
            })}
          </ul>
        )}
        <div>全部: {record.evt_regs?.length || 0}</div>
        <div>
          成功:{' '}
          {record.evt_regs?.filter((it) => it.status === 'success').length || 0}
        </div>
        <div>
          取消:{' '}
          {record.evt_regs?.filter((it) => it.status === 'failure').length || 0}
        </div>
      </div>,
    ];
  } else if (col.customType === 'twpaa-internal_news-title') {
    const url = `/admin/internal_news/?action=detail&id=${record.id}`;
    return [
      true,
      <a
        style={{display: 'inline-block', maxWidth: 1080}}
        href={url}
        onClick={(e) => {
          e.preventDefault();
          AppActions.navigate(url);
        }}>
        {record.title}
      </a>,
    ];
  } else if (col.customType === 'twpaa-internal_news-type') {
    const labels = {
      important: '重要公告',
      internal: '會務訊息',
      external: '會外新訊',
    };

    return [
      true,
      <ul style={{padding: 0, width: 100}}>
        {(record.type || []).map((it) => {
          return <li key={it}>{labels[it]}</li>;
        })}
      </ul>,
    ];
  } else if (col.customType === 'twpaa-article_title') {
    return [
      true,
      <div>
        <h3
          onClick={() => {
            AppActions.navigate(
              `/admin/articles/?action=detail&id=${record.id}`,
            );
          }}>
          {record.title}
        </h3>
      </div>,
    ];
  } else if (col.customType === 'twpaa-article_link') {
    return [
      true,
      <Ant.Button
        type="link"
        size="small"
        onClick={() => {
          if (record.web_link) {
            window.open(record.web_link, '_target');
          }
        }}>
        開啟
      </Ant.Button>,
    ];
  }

  return [false, null];
}
