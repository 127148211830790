import React from 'react';
import {Button, message} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
const qs = require('query-string');

export default function AdminPageBuilderButtonAction(props) {
  const {location} = props;
  const {id} = qs.parse(location.search);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 12,
        position: 'fixed',
        right: 0,
        bottom: 0,
        boxShadow: '0px 0px 6px 2px rgba(0,0,0,0.2)',
        zIndex: 9999,
      }}>
      <Button
        disabled={!id}
        onClick={async () => {
          const nextValues = {...props.context.values};
          delete nextValues.id;
          AppActions.setLoading(true);
          try {
            await JStorage.updateDocument(
              'page',
              {
                id,
              },
              nextValues,
            );
            message.success('成功更新');
          } catch (ex) {
            console.warn(ex);
            message.error('API error');
          }
          AppActions.setLoading(false);
        }}>
        更新
      </Button>
      <Button
        onClick={() => {
          window.scrollTo({top: 0, behavior: 'smooth'});
        }}>
        滑動至頂
      </Button>
      <Button
        onClick={() => {
          window.scrollTo({top: 1000000, behavior: 'smooth'});
        }}>
        滑動至底
      </Button>
      <Button
        disabled={!id}
        href={`/page-preview/?id=${id}&no_footer=1&no_navbar=1`}
        target="_blank">
        內容預覽
      </Button>
      <Button disabled={!id} href={`/page-preview/?id=${id}`} target="_blank">
        全頁預覽
      </Button>
    </div>
  );
}
