import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {renderCategoryOption} from '../TwpaaCourseItem';

const pageSize = 1000;

export default function TwpaaUserStudyHoursButton(props) {
  const {size = 'medium'} = props;
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  return (
    <>
      <Ant.Button
        size={size}
        onClick={() => {
          setModalData({visible: true});
        }}>
        {props.title}
      </Ant.Button>
      <TwpaaUserStudyHoursModal
        close={() => setModalData({visible: false})}
        {...modalData}
        {...props}
      />
    </>
  );
}

function TwpaaUserStudyHoursModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={1200}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

export function groupStudyHoursByYear(records) {
  let currYear = null;
  let sum = 0;
  let sums = {};

  return records.reduce((acc, it, idx) => {
    const y = it.date?.split('-')[0];

    if (currYear !== y && idx > 0) {
      acc.push({id: currYear, hours: sum, year: currYear, sums});
      sum = it.hours;
      sums = Object.keys(it.hours_catagory).reduce((aacc, k) => {
        if (it.hours_catagory[k]) {
          aacc[k] = sum;
        }
        return aacc;
      }, {});
    } else {
      sum += it.hours;
      sums = Object.keys(it.hours_catagory).reduce((aacc, k) => {
        if (it.hours_catagory[k]) {
          aacc[k] = (sums[k] || 0) + it.hours;
        }
        return aacc;
      }, sums);
      console.log('DBGGG', sums);
    }

    currYear = y;
    acc.push(it);

    if (idx === records.length - 1) {
      // last one
      acc.push({
        id: currYear,
        hours: sum,
        year: currYear,
        sums,
      });
    }

    console.log('DBGGG', acc);
    return acc;
  }, []);
}

function ModalContent(props) {
  const {record} = props;
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {
        id_card_number: record.id_card_number,
      };

      const resp = await JStorage.fetchDocuments(
        'study_hour',
        extraQuery,
        ['-date'],
        paging,
      );

      setFetchResp({
        ...resp,
        results: groupStudyHoursByYear(resp.results),
      });
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [paging, record.id_card_number]);

  React.useEffect(() => {
    fetchDocumentList();
  }, [fetchDocumentList]);

  return (
    <Wrapper>
      <h2 style={{marginBottom: 30}}>進修時數紀錄</h2>
      <Ant.Table
        columns={[
          {
            title: '日期',
            key: 'date',
            dataIndex: 'date',
          },
          {
            title: '活動名稱',
            key: 'course_name',
            render: (_, record) => {
              if (record.year) {
                return (
                  <div style={{textAlign: 'right', fontWeight: 'bold'}}>
                    {record.year}年時數小計
                  </div>
                );
              } else {
                return record.course_name;
              }
            },
          },
          {
            title: '時數',
            key: 'hours',
            dataIndex: 'hours',
          },
          {
            title: '時數類別',
            key: 'category',
            render: (_, record) => {
              if (record.sums) {
                return (
                  <div>
                    {Object.keys(record.sums).map((k) => {
                      const labels = {
                        option_1: '獎',
                        option_2: '職',
                        option_3: '訴',
                        option_4: '商',
                      };
                      return (
                        <div key={k}>
                          {labels[k]}/{record.sums[k]}
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return (
                <CourseCat>
                  {record.hours_catagory?.option_1 &&
                    renderCategoryOption('option_1')}
                  {record.hours_catagory?.option_2 &&
                    renderCategoryOption('option_2')}
                  {record.hours_catagory?.option_3 &&
                    renderCategoryOption('option_3')}
                  {record.hours_catagory?.option_4 &&
                    renderCategoryOption('option_4')}
                </CourseCat>
              );
            },
          },
          {
            title: '',
            key: 'action',
            render: (_, record) => {
              if (record.year) {
                return null;
              }

              return (
                <Ant.Button
                  size="small"
                  onClick={() => {
                    window.open(
                      `/admin/study_hours/?action=detail&id=${record.id}`,
                    );
                  }}>
                  修改
                </Ant.Button>
              );
            },
          },
        ]}
        dataSource={fetchResp?.results}
        rowKey={'id'}
        pagination={{
          size: 'small',
          total: fetchResp?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
          current: paging.offset / pageSize + 1,
          pageSize: pageSize,
          position: ['bottomCenter'],
          onChange: (page, pageSize) => {
            if (pageSize) {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }
          },
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

const CourseCat = styled.ul`
  display: flex;
  gap: 6px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 13px;
  }
`;
