import React from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import RichTextEditor from '../../Components/RichTextEditor';
import {serializeContent} from '../../Components/RichTextEditor/serializeToHtml';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Ant from 'antd';

const MULTILINGUAL_FIELDS = {
  content: 'tw',
  content_en: 'en',
  content_jp: 'jp',
};

async function upsertArticle(record, document, options = {}) {
  const {
    collectionName = 'Article_Default',
    primaryKey = 'id',
    token = '',
    onUpdate = () => {},
    onClose = () => {},
  } = options;

  AppActions.setLoading(true);
  try {
    let createdDoc;

    if (document) {
      // edit
      createdDoc = await JStorage.updateDocument(
        collectionName,
        {
          [primaryKey]: document[primaryKey],
        },
        record,
        {token},
      );
      Ant.message.success('編輯成功');
    } else {
      createdDoc = await JStorage.createDocument(collectionName, record, {
        token,
      });
      Ant.message.success('新增成功');
    }

    onUpdate(createdDoc);
    onClose();
  } catch (ex) {
    console.warn(ex);
    Ant.message.error('發生錯誤');
  }
  AppActions.setLoading(false);
}

export default function ArticleBelowSection({
  name,
  type,
  context,
  path,
  multilingual = false,
  collectionName = 'Article_Default',
}) {
  const {position, instance, values, setValues, errors, formSpec} = context;
  const {schema, uiSchema, errorSchema} = formSpec;
  const [contents, setContents] = React.useState({
    content: undefined,
    content_en: undefined,
    content_jp: undefined,
  });
  const [tab, setTab] = React.useState('content'); // available if multilingual

  React.useEffect(() => {
    if (instance) {
      setContents((prev) => ({...prev, content: instance['content']}));
      if (multilingual) {
        setContents((prev) => ({
          ...prev,
          content_en: instance['content_en'],
          content_jp: instance['content_jp'],
        }));
      }
    }
  }, [instance, multilingual]);

  const submit = async () => {
    // get article content & html

    const formData = {
      ...values,
      content: contents.content,
      html: await serializeContent(contents.content),
      ...(multilingual && {
        content_en: contents.content_en,
        html_en: await serializeContent(contents.content_en),
        content_jp: contents.content_jp,
        html_jp: await serializeContent(contents.content_jp),
      }),
    };

    // workaround: field value will be set to undefined  when its an empty string,
    // so we have to manually turn it back
    for (const field in formData) {
      if (
        formData[field] === undefined &&
        instance &&
        typeof instance[field] === 'string'
      ) {
        formData[field] = '';
      }
    }

    await upsertArticle(formData, instance, {
      collectionName,
      onUpdate: (createdDoc) =>
        AppActions.navigate(path + `?action=detail&id=${createdDoc.id}`),
    });
  };

  return (
    <Wrapper>
      <h2>文章內容</h2>
      {multilingual &&
        Object.keys(MULTILINGUAL_FIELDS).map((key) => (
          <Ant.Button
            type={tab === key ? 'primary' : 'default'}
            onClick={() => setTab(key)}>
            {MULTILINGUAL_FIELDS[key]}
          </Ant.Button>
        ))}
      <div className="content">
        {multilingual && tab === 'content_jp' ? (
          '尚未開放'
        ) : multilingual ? (
          <RichTextEditor
            options={{}}
            token=""
            key={tab}
            onChange={(value) =>
              setContents((prev) => ({...prev, [tab]: value}))
            }
            content={contents[tab]}
          />
        ) : (
          <RichTextEditor
            options={{}}
            token=""
            onChange={(value) =>
              setContents((prev) => ({...prev, content: value}))
            }
            content={contents.content}
          />
        )}
      </div>
      <div className="footer">
        {instance && (
          <div>
            建立：{new Date(instance.created).toLocaleString('sv')}
            {' / '}
          </div>
        )}
        {instance && (
          <div>
            更新：{new Date(instance.updated).toLocaleString('sv')}
            {' / '}
          </div>
        )}
        {instance && <div>{instance.id}</div>}

        <div style={{flex: 1}} />
        <Ant.Button
          type="primary"
          onClick={() => {
            submit();

            if (!instance) {
              setTimeout(() => {
                AppActions.navigate(window.location.pathname);
              }, 500);
            }
          }}>
          {instance ? '編輯' : '新增'}
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 30px;

  & > h2 {
    margin: 0px;
  }

  & .content {
    padding: 8px;
    background-color: #fff;
    margin-bottom: 30px;
  }

  & > .footer {
    display: flex;
    align-items: center;

    & > div {
      color: #aaa;
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;
