import React from 'react';
import * as Ant from 'antd';
import styled from 'styled-components';
import {zip3} from 'rev.sdk.js/Constants/taiwan';

export default function RjsfAddressField(props) {
  const {formData, schema, uiSchema = {}, extra = {}} = props;
  const [fieldData, setFieldData] = React.useState(formData || {});
  const title =
    schema?.title || schema?.properties?.title || extra?.title || '地址';
  const extraCss = uiSchema['rev:css'] || extra?.css || '';

  const allCities = [...Array.from(new Set(zip3.map((it) => it.city))), '海外'];
  const allDistricts = zip3.filter((it) => it.city === fieldData.city);

  return (
    <Wrapper extraCss={extraCss}>
      <label>{title}</label>
      <div style={{flex: 1}}>
        <div
          style={{
            display: 'flex',
            gap: 8,
            flexWrap: 'wrap',
            alignItems: 'center',
          }}>
          <Ant.Select
            style={{width: 100}}
            value={fieldData.city}
            onChange={(value) => {
              setFieldData({...fieldData, city: value, district: '', zip: ''});
              props.onChange({
                ...fieldData,
                city: value,
                district: '',
                zip: '',
              });
            }}>
            {allCities.map((it) => {
              return (
                <Ant.Select.Option value={it} key={it}>
                  {it}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
          <Ant.Select
            style={{width: 100}}
            value={fieldData.district}
            onChange={(value) => {
              const zip = allDistricts.find((it) => it.district === value)?.zip;
              setFieldData({...fieldData, district: value, zip});
              props.onChange({...fieldData, district: value, zip});
            }}>
            {allDistricts.map((it) => {
              return (
                <Ant.Select.Option value={it.district} key={it.district}>
                  {it.district}
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
          {fieldData.zip && (
            <div style={{color: '#ccc'}}>郵遞區號：{fieldData.zip}</div>
          )}
        </div>
        <Ant.Input
          style={{width: '100%', marginTop: 8}}
          placeholder="詳細地址"
          value={fieldData.detail}
          onChange={(e) => {
            setFieldData({...fieldData, detail: e.target.value});
            props.onChange({...fieldData, detail: e.target.value});
          }}
        />
        <div className="help">* 若為海外地址，詳細資訊內請包含郵遞區號</div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 30px 0;

  & > label {
    width: 80px;
  }

  & .help {
    color: #888;
  }

  ${(props) => props.extraCss}
`;
