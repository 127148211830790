export const UserProfile = {
  exportSpec: {
    member_id: {
      title: '會員編號',
      type: 'number',
    },
    _first_login: {
      title: '訴代資訊(1)',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        if (!record.first_login?.checked) {
          return '';
        }
        return `${record.first_login?.login_number || '---'} / ${
          record.first_login?.agency || '---'
        } / ${record.first_login?.login_qualification || '---'} / ${
          record.first_login?.first_login_date || '---'
        }`;
      },
    },
    _stay_login: {
      title: '訴代資訊(2)',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        if (!!record.stay_login?.checked) {
          return '';
        }
        return `${record.stay_login?.cancel_login_date || '---'}`;
      },
    },
    _expertise: {
      title: '專長',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        return Object.keys(record.expertise || {})
          .filter((k) => !!record.expertise[k])
          .filter((k) => k !== 'etc')
          .map((k) => {
            if (k === 'etc_text') {
              return record.expertise.etc_text;
            }
            return {
              mechanical: '機械',
              operational: '作業運輸',
              biotechnology: '生技醫藥',
              chemical: '化工',
              electronic: '電子及電力',
              semiconductor: '半導體製程及元件',
              information: '資通訊',
              photoelectric: '光電物理',
              supplies: '其他民生用品',
            }[k];
          })
          .join(',');
      },
    },
    status: {
      title: '狀態',
      type: 'string',
      enum: [
        'pending',
        'reviewing',
        'valid',
        'invalid',
        'withdraw_reviewing',
        'withdraw',
      ],
      enumNames: ['申請中', '審核中', '有效', '停權', '退會申請中', '退會'],
    },
    name: {
      title: '中文姓名',
      type: 'string',
    },
    name_eng: {
      title: '英文姓名 (同護照/英文半形)',
      type: 'string',
    },
    gender: {
      type: 'boolean',
      title: '性別',
      enum: [true, false],
      enumNames: ['男', '女'],
    },
    id_card_number: {
      title: '身分證字號',
      type: 'string',
    },
    birthday: {
      title: '出生年月日',
      type: 'string',
    },
    phone: {
      title: '手機',
      type: 'string',
    },
    email: {
      title: 'Email',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        return [record.email, record.email_2, record.secretary_email]
          .filter((it) => !!it)
          .join(';');
      },
    },
    contact_address: {
      title: '聯絡地址',
      type: 'object',
      properties: {
        city: {
          type: 'string',
        },
        district: {
          type: 'string',
        },
        zip: {
          type: 'string',
        },
        detail: {
          type: 'string',
        },
      },
    },
    certificate_number: {
      title: '專利師證書字號',
      type: 'string',
    },
    national_examination_year: {
      title: '國考年度',
      type: 'string',
    },
    agency_name: {
      title: '中文全稱',
      type: 'string',
    },
    agency_name_eng: {
      title: '英文全稱',
      type: 'string',
    },
    agency_address: {
      title: '執行業務處所地址',
      type: 'object',
      properties: {
        city: {
          type: 'string',
        },
        district: {
          type: 'string',
        },
        zip: {
          type: 'string',
        },
        detail: {
          type: 'string',
        },
      },
    },
    agency_telephone: {
      title: '聯絡電話',
      type: 'string',
    },
    agency_telephone_ext: {
      title: '聯絡電話分機',
      type: 'string',
    },
    agency_fax: {
      title: '傳真',
      type: 'string',
    },
    agency_business_id_number: {
      title: '統一編號',
      type: 'string',
    },
    public: {
      type: 'boolean',
      title:
        '是否同意將本人執行業務處所與聯絡電話等資料(中/英文)刊登於公會網頁',
      enum: [true, false],
      enumNames: ['同意', '不同意'],
    },
    receipt_name: {
      title: '收據抬頭',
      type: 'string',
    },
    business_id_number: {
      title: '統編',
      type: 'string',
    },
    join_date: {
      title: '入會日期',
      type: 'string',
    },
    withdraw_date: {
      title: '退會日期',
      type: 'string',
    },
    secretary_name: {
      title: '秘書姓名',
      type: 'string',
    },
    secretary_phone: {
      title: '秘書電話',
      type: 'string',
    },
    punishment_agency: {
      title: '懲處機關',
      type: 'string',
    },
    case_number: {
      title: '案號',
      type: 'string',
    },
    resolution_text: {
      title: '決議主文',
      type: 'string',
    },
    resolution_date: {
      title: '決議日期',
      type: 'string',
    },
  },
};

export const Product = {
  exportSpec: {
    name: {
      type: 'string',
      title: '活動名稱',
    },
    _hours_catagory: {
      type: 'string',
      title: '時數類別',
    },
    _status_line: {
      type: 'string',
      title: '報名狀態',
    },
    _success_cnt: {
      type: 'string',
      title: '報名成功人數',
    },
    _checkin_cnt: {
      type: 'string',
      title: '報到人數',
    },
    date: {
      type: 'string',
      title: '日期',
    },
    open_time: {
      type: 'string',
      title: '開始時間',
    },
    close_time: {
      type: 'string',
      title: '結束時間',
    },
    location: {
      type: 'string',
      title: '地點',
    },
    host: {
      type: 'string',
      title: '主辦單位',
    },
    lecturer: {
      type: 'string',
      title: '主講人',
    },
    series: {
      type: 'array',
      title: '系列課程',
      items: {
        type: 'string',
      },
    },
    public: {
      type: 'boolean',
      title: '公開',
      default: false,
    },
    price: {
      type: 'number',
      title: '價錢',
      default: 100,
    },
    hours: {
      type: 'number',
      title: '時數',
    },
    join_member: {
      type: 'boolean',
      title: '會員可加入',
      enum: [true, false],
      enumNames: ['是', '否'],
    },
    price_member: {
      type: 'number',
      title: '會員費用',
    },
    join_non_member: {
      type: 'boolean',
      title: '非會員可加入',
      enum: [true, false],
      enumNames: ['是', '否'],
    },
    price_non_member: {
      type: 'number',
      title: '非會員費用',
    },
    max_reg_cnt: {
      type: 'number',
      title: '報名人數上限',
      default: 0,
    },
    open_date: {
      type: 'string',
      title: '開始報名日期',
    },
    close_date: {
      type: 'string',
      title: '截止報名日期',
    },
    private_file_required: {
      type: 'boolean',
      title: '報名時需上傳檔案',
    },
    invoice_required: {
      type: 'boolean',
      title: '報名時需填寫發票/收據資訊',
    },
    meal_info_required: {
      type: 'boolean',
      title: '報名時需填寫用餐資訊',
    },
    trademark_info_required: {
      type: 'boolean',
      title: '報名時需填寫商標代理人字號',
    },
    is_pre_training_course: {
      type: 'boolean',
      title: '是否為職前訓練課程',
    },
  },
};

export const EventRegistration = {
  exportSpec: {
    serial_number: {
      title: '報名序號',
      type: 'number',
      readOnly: true,
    },
    is_check_in: {
      title: '已報到',
      type: 'boolean',
      default: false,
    },
    member_id: {
      title: '會員編號',
      type: 'number',
      render: ({exportSpec, context, record, key}) => {
        if (typeof record.member_id !== 'number') {
          return '非會員';
        }
        return ('0000' + record.member_id).slice(-4);
      },
    },
    name: {
      title: '姓名',
      type: 'string',
    },
    certificate_number: {
      title: '專利師證書字號',
      type: 'string',
    },
    agency_name: {
      title: '執行業務處所',
      type: 'string',
    },
    phone: {
      title: '電話',
      type: 'string',
    },
    email: {
      title: 'EMAIL',
      type: 'string',
    },
    contact_address: {
      type: 'object',
      title: '聯絡地址',
      properties: {
        city: {
          type: 'string',
        },
        district: {
          type: 'string',
        },
        zip: {
          type: 'string',
        },
        detail: {
          type: 'string',
        },
      },
    },
    _invoice: {
      title: '發票/收據資訊',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        if (record.invoice?.type === 'personal') {
          return '個人（二聯式';
        } else if (record.invoice?.type === 'company') {
          return `公司報帳（三聯式）: ${record.invoice?.invoice_title} / ${record.invoice?.tax_id_number}`;
        }
        return '';
      },
    },
    trademark_info: {
      title: '商標代理人字號',
      type: 'string',
    },
    id_card_number: {
      title: '身分證字號',
      type: 'string',
    },
    bank_account_last_5_digits: {
      title: '匯款帳號後5碼',
      type: 'string',
    },
    note: {
      title: '備註',
      type: 'string',
    },
    private_file: {
      title: '附加檔案',
      type: 'string',
    },
    status: {
      title: '狀態',
      type: 'string',
      enum: ['pending', 'success', 'failure'],
      enumNames: ['報名中', '報名成功', '取消報名'],
      default: 'pending',
    },
    _course_price: {
      title: '課程價格',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        console.log('DBGGG', context);
        console.log('DBGGG', record);
        if (record.owner) {
          return context?.price;
        } else {
          return context?.price_non_member;
        }
      },
    },
    _course_hours: {
      title: '課程時數',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        return context?.hours;
      },
    },
    _course_hours_category: {
      title: '課程時數類別',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        return Object.keys(context?.hours_catagory || {})
          .filter((k) => !!context.hours_catagory[k])
          .map((k) => {
            return {
              option_1: '會員在職進修獎勵時數',
              option_2: '專利師在職進修時數',
              option_3: '專利訴訟業務相關進修時數',
              option_4: '商標進修時數',
            }[k];
          })
          .join(',');
      },
    },
    _created: {
      title: '報名日期',
      type: 'string',
      render: ({exportSpec, context, record, key}) => {
        return new Date(record.created).toLocaleDateString('sv');
      },
    },
  },
};

export const CommitteeMember = {
  exportSpec: {
    name: {
      type: 'string',
      title: '委員會',
    },
    session: {
      type: 'number',
      title: '屆別',
    },
    role: {
      type: 'string',
      title: '職稱',
    },
    _member_id: {
      type: 'string',
      title: '會員編號',
    },
    _username: {
      type: 'string',
      title: '會員',
    },
    _email: {
      type: 'string',
      title: 'EMAIL',
    },
    _phone: {
      type: 'string',
      title: '電話',
    },
    _agency_name: {
      type: 'string',
      title: '執行業務處所',
    },
    tenure: {
      type: 'string',
      title: '任別',
    },
    start_tenure: {
      type: 'string',
      title: '任期開始日期',
    },
    end_tenure: {
      type: 'string',
      title: '任期結束日期',
    },
  },
};

export const SupervisorMember = {
  exportSpec: {
    name: {
      type: 'string',
      title: '理監事',
    },
    session: {
      type: 'number',
      title: '屆別',
    },
    role: {
      type: 'string',
      title: '職稱',
    },
    _member_id: {
      type: 'string',
      title: '會員編號',
    },
    _username: {
      type: 'string',
      title: '會員',
    },
    _email: {
      type: 'string',
      title: 'EMAIL',
    },
    _phone: {
      type: 'string',
      title: '電話',
    },
    _agency_name: {
      type: 'string',
      title: '執行業務處所',
    },
    tenure: {
      type: 'string',
      title: '任別',
    },
    start_tenure: {
      type: 'string',
      title: '任期開始日期',
    },
    end_tenure: {
      type: 'string',
      title: '任期結束日期',
    },
  },
};
