import React from 'react';
import {Button, message, Popconfirm} from 'antd';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as CatUtil from '../../Utils/CatUtil';
import * as AppActions from '../../AppActions';
import AdminLinkButton from './AdminLinkButton';
import TwpaaExportCourseButton from '../../Components/TwpaaExportCourseButton';

export function handleProducts({path, pageContext}) {
  if (path.indexOf('/admin/archived_products') > -1) {
    const firstCol = pageContext.resource.columns[0];
    pageContext.resource.columns[0] = {
      ...firstCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;
        return <AdminLinkButton url={url} title={record.name} />;
      },
    };
  }

  if (path.indexOf('/admin/products') > -1) {
    const secondCol = pageContext.resource.columns[1];
    pageContext.resource.columns[1] = {
      ...secondCol,
      render: (text, record) => {
        const url = `/admin/products/?action=detail&id=${record.id}`;
        return (
          <a
            href={url}
            style={{maxWidth: 960, display: 'inline-block'}}
            onClick={(e) => {
              e.preventDefault();
              AppActions.navigate(url);
            }}>
            {record.name}
          </a>
        );
      },
    };

    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex', gap: 10}}>
          <Button
            onClick={() => {
              AppActions.navigate(`/admin/products/?action=create`);
            }}>
            +
          </Button>
          <TwpaaExportCourseButton />
        </div>
      );
    };

    pageContext.resource.renderDetailButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>
          <Button
            onClick={() => {
              window.open(`/admin/event-check-in/?id=${record.id}`, '_blank');
            }}>
            報名詳情
          </Button>

          <Button
            onClick={() => {
              window.open(
                `/admin/study_hours/?ext_res_def=${encodeURIComponent(
                  JSON.stringify({
                    name: `所有會員進修時數 / ${record.name}`,
                    renderCreateButton: null,
                    predefinedQueries: [
                      {
                        event_id: record.id,
                      },
                    ],
                  }),
                )}`,
                '_blank',
              );
            }}>
            相關進修時數
          </Button>

          {!record.parent && (
            <Button
              onClick={async () => {
                let [err, childCourse] = await AppActions.tryError(
                  Jstorage.fetchOneDocument('product', {
                    parent: record.id,
                  }),
                );

                if (!childCourse) {
                  if (
                    window.confirm(
                      `確定要創建對應的${
                        record.online ? '實體' : '線上'
                      }課程嗎?`,
                    )
                  ) {
                    AppActions.setLoading(true);
                    const nextRecord = {...record};
                    delete nextRecord.id;
                    delete nextRecord._id;
                    nextRecord.parent = record.id;
                    nextRecord.online = !nextRecord.online;
                    childCourse = await Jstorage.createDocument(
                      'product',
                      nextRecord,
                    );
                    AppActions.setLoading(false);
                  } else {
                    return;
                  }
                }

                AppActions.navigate(
                  `/admin/products/?action=detail&id=${childCourse.id}`,
                );
              }}>
              對應{record.online ? '實體' : '線上'}課程
            </Button>
          )}
        </div>
      );
    };
  }
}
