import React, {useState} from 'react';
import * as Ant from 'antd';
import RichTextEditor from '../RichTextEditor';
import {serializeContent} from '../RichTextEditor/serializeToHtml';
import {getOutlet, useOutlet} from 'reconnect.js';

export default function RichTextField(props) {
  let {schema, uiSchema, formData, onChange} = props;

  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState();

  React.useEffect(() => {
    if (Array.isArray(props.formData?.content)) {
      setContent(props.formData?.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formData?.content]);

  if (uiSchema.hidden) {
    return null;
  }

  return (
    <div
      style={{
        margin: '10px 0',
        maxWidth: 800,
      }}>
      <div style={{marginBottom: 10}}>{schema.title || schema.title}</div>
      <div
        style={{
          border: '1px #999',
          borderStyle: !!formData.html ? 'solid' : 'dashed',
          backgroundColor: !!formData.html ? '#fff' : '#ddd',
          width: '100%',
          height: 300,
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}>
        {formData.html ? (
          <div
            style={{
              padding: 5,
              maxWidth: '100%',
              height: '100%',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}>
            <div
              dangerouslySetInnerHTML={{__html: formData.html}}
              style={{width: '100%'}}
            />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#999',
            }}>
            新增(目前無內容)
          </div>
        )}
      </div>

      <Ant.Modal
        open={openModal}
        width="80%"
        onCancel={() => setOpenModal(false)}
        destroyOnClose={true}
        onOk={async () => {
          await onChange({
            content,
            html: await serializeContent(content),
          });
          setOpenModal(false);
        }}>
        {openModal && (
          <RichTextEditor
            options={{}}
            token={
              uiSchema.public_upload ? '' : getOutlet('user').getValue()?.token
            }
            onChange={(value) => setContent(value)}
            content={content}
          />
        )}
      </Ant.Modal>
    </div>
  );
}
