import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {genExcelFile} from '../../Utils/ExportUtil';
import * as AllExports from '../../twpaa-export';
import {getDisableLabels} from '../../Components/TwpaaCourseItem';

const ExportConfig = {
  collection: 'product',
  title: '課程資料匯出',
  downloadTitle: '課程資料',
  genExcelFile: genExcelFile.bind(null, AllExports.Product.exportSpec),
};

export default function TwpaaExportCourseButton(props) {
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  return (
    <>
      <Ant.Button
        onClick={() => {
          setModalData({visible: true});
        }}>
        匯出
      </Ant.Button>
      <TwpaaExportCourseModal
        close={() => setModalData({visible: false})}
        {...modalData}
      />
    </>
  );
}

function TwpaaExportCourseModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [records, setRecords] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const maxYear = new Date().getFullYear() + 1;
  const minYear = 2010;
  const [selectedYear, setSelectedYear] = React.useState(maxYear - 1);

  const years = [];
  let y = maxYear;
  while (y >= minYear) {
    years.push(y--);
  }

  React.useEffect(() => {
    async function fetchData() {}

    if (keyword) {
      fetchData();
    }
  }, [keyword]);

  return (
    <Wrapper>
      <h2 style={{marginBottom: 30}}>{ExportConfig.title}</h2>

      <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
        <Ant.Select value={selectedYear} onChange={setSelectedYear}>
          {years.map((it) => {
            return (
              <Ant.Select.Option key={it} value={it}>
                {it}
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>

        <label>關鍵字</label>
        <Ant.Input
          style={{flex: 1}}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Ant.Button
          onClick={async () => {
            const [err, resp] = await AppActions.tryError(async () => {
              const extraQueries = {
                date: {$regex: `^${selectedYear}-`},
                archived: {$ne: true},
              };

              if (keyword) {
                extraQueries.name = {$regex: keyword};
              }

              const results = await JStorage.aggregateDocuments('product', [
                {
                  $match: extraQueries,
                },
                {
                  $sort: {date: -1},
                },
                {
                  $addFields: {
                    event_id: {
                      $toString: '$_id',
                    },
                  },
                },
                {
                  $lookup: {
                    from: 'event_registration',
                    localField: 'event_id',
                    foreignField: 'event_id',
                    as: 'evt_regs',
                  },
                },
                {
                  $project: {
                    'evt_regs.product': 0,
                  },
                },
              ]);

              return results
                .map((it) => {
                  return {
                    ...it,
                    id: it._id.$oid,
                    reg_cnt: it.evt_regs.filter((itt) => {
                      return itt.status !== 'failure';
                    }).length,
                  };
                })
                .map((it) => {
                  return {
                    ...it,
                    _status_line: getDisableLabels(it).join(',') || '可報名',
                    _success_cnt:
                      it.evt_regs?.filter((it) => it.status === 'success')
                        .length || 0,
                    _checkin_cnt:
                      it.evt_regs?.filter(
                        (it) => it.status === 'success' && it.is_check_in,
                      ).length || 0,
                    _hours_catagory: Object.keys(it.hours_catagory || {})
                      .filter((k) => !!it.hours_catagory[k])
                      .map((k) => {
                        return {
                          option_1: '會員在職進修獎勵時數',
                          option_2: '專利師在職進修時數',
                          option_3: '專利訴訟業務相關進修時數',
                          option_4: '商標進修時數',
                        }[k];
                      })
                      .join(','),
                  };
                });
            });
            if (err) {
              Ant.message.warn('API Error: ' + err);
            } else {
              if (resp.length > 0) {
                setRecords(resp);
                setDownloadUrl(await ExportConfig.genExcelFile(resp));
              } else {
                Ant.message.info('查無資料');
                setRecords([]);
                setDownloadUrl(null);
              }
            }
          }}>
          確認
        </Ant.Button>
        <Ant.Button
          type="link"
          onClick={() => {
            setKeyword('');
            setRecords([]);
            setDownloadUrl(null);
          }}>
          清除
        </Ant.Button>
      </div>

      {records.length > 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 20,
          }}>
          <div>共 {records.length} 筆紀錄符合</div>
          {downloadUrl && (
            <Ant.Button
              type="link"
              target="_blank"
              download={`${ExportConfig.downloadTitle}.xlsx`}
              href={downloadUrl}>
              下載
            </Ant.Button>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
