import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import DataJson from '../../../data.json';
import * as AppActions from '../../AppActions';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {getCdnUrl} from '../../Utils/CdnUtil';

function genFilePrefix() {
  let randstr = '';
  let datestr = '';
  try {
    randstr = ('000000' + Math.floor(Math.random() * 1000000)).slice(-6);
    datestr = new Date().toLocaleString('sv').split(' ')[0];
  } catch (ex) {}
  return `${datestr}/${randstr}`;
}

async function uploadFile({file, name, onSuccess}) {
  try {
    AppActions.setLoading(true);
    const resp = await ApiUtil.req(
      `${DataJson.storageHost}/storage/presigned/url?client_id=${DataJson.clientId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          'Content-Type': file.type,
          key: `${genFilePrefix()}/${name.replace(/[#+&?=]/g, '_')}`,
        },
      },
    );

    const {expected, fields, url} = resp;
    const formData = new FormData();

    for (const key in fields) {
      formData.set(key, fields[key]);
    }
    formData.set('file', file);

    const uploadResp = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (uploadResp.status.toString()[0] !== '2') {
      throw uploadResp;
    }

    await onSuccess(expected);
  } catch (ex) {
    console.warn(ex);
    alert('上傳失敗: 請稍後再試');
  } finally {
    AppActions.setLoading(false);
  }
}

function SelectFileModal(props) {
  const {modalData, setModalData, uiSchema} = props;
  const visible = !!modalData;

  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {visible && (
        <ModalContent
          modalData={modalData}
          setModalData={setModalData}
          uiSchema={uiSchema}
        />
      )}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData, uiSchema} = props;
  const fileInputRef = React.useRef();
  const [filename, setFilename] = React.useState(null);
  const [objUrl, setObjUrl] = React.useState(null);

  return (
    <Wrapper
      style={{
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      {uiSchema['rev:preview'] && objUrl && (
        <img
          src={objUrl}
          alt="selected-file"
          style={{
            width: '256px',
            objectFit: 'contain',
            border: '1px dashed #ccc',
          }}
        />
      )}
      <div className="controls">
        <div>
          <label
            htmlFor="badge-file-upload-widget-input"
            style={{
              backgroundColor: 'white',
              border: '1px solid #888',
              display: 'block',
              width: '100%',
              textAlign: 'center',
              fontSize: 14,
              color: '#888',
              padding: 4,
              borderRadius: 2,
            }}>
            選擇檔案 {filename ? filename : ''}
          </label>
          <input
            id="badge-file-upload-widget-input"
            ref={fileInputRef}
            type="file"
            style={{display: 'none'}}
            accept={uiSchema['rev:accept'] || '*'}
            onChange={() => {
              const f = fileInputRef.current.files[0];

              if (f) {
                setFilename(f.name);
                setObjUrl(URL.createObjectURL(f));
              } else {
                setFilename(null);
                setObjUrl(null);
              }
            }}
          />
        </div>

        <Ant.Button
          disabled={!filename}
          style={{width: '100%', marginTop: 8}}
          type="primary"
          onClick={() => {
            const f = fileInputRef.current.files[0];
            uploadFile({
              file: f,
              name: f.name,
              onSuccess: async (resultUrl) => {
                Ant.message.success('Success!');
                modalData.onChange(resultUrl);
                setModalData(null);
                await AppActions.delay(500);
              },
            });
          }}>
          上傳
        </Ant.Button>
      </div>
    </Wrapper>
  );
}

export default function FileUploadWidget(props) {
  const {noPreview} = props;
  const [modalData, setModalData] = React.useState(null);

  function renderThumbnail() {
    if (noPreview) {
      return null;
    }

    if (!props.value) {
      return null;
    } else {
      const showImage = props.uiSchema['rev:preview'];
      return (
        <a
          href={getCdnUrl(props.value)}
          target="_blank"
          rel="noreferrer"
          style={{marginRight: 10}}>
          {showImage ? (
            <img
              src={props.value}
              alt="uploaded"
              style={{
                width: 120,
                height: 120,
                objectFit: 'contain',
                border: '3px solid white',
                borderRadius: 4,
              }}
            />
          ) : (
            props.value.split('/').slice(-1)[0]
          )}
        </a>
      );
    }
  }

  return (
    <Wrapper>
      <div>{renderThumbnail()}</div>

      <div className="controls">
        <Ant.Button
          onClick={() =>
            setModalData({
              onChange: props.onChange,
            })
          }>
          選擇檔案上傳
        </Ant.Button>
        {props.value && (
          <Ant.Button
            type="link"
            style={{color: 'black'}}
            onClick={() => {
              props.onChange('');
            }}>
            移除
          </Ant.Button>
        )}
      </div>
      <SelectFileModal
        modalData={modalData}
        setModalData={setModalData}
        uiSchema={props.uiSchema || {}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  display: flex;
  align-items: center;
`;
