import React, {useCallback, useState} from 'react';
import {useSlate} from 'slate-react';
import {Transforms, Editor} from 'slate';
import styled from 'styled-components';

const CUSTOM_PROPERTIES = ['url'];

const setFontColorValue = (editor, value) => {
  Transforms.select(editor, editor._onBlurSelection);
  if (value) {
    Editor.addMark(editor, 'color', value.toString());
  }
};

const getFontColorValue = (editor) => {
  const defaultColor = '#000000';
  const marks = Editor.marks(editor);
  if (marks && marks.color) {
    return marks.color;
  }
  return defaultColor;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const IconWrapper = (props) => {
  return (
    <div
      style={{
        margin: 3,
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: props.active ? 'bold' : 'normal',
        backgroundColor: props.active ? '#eee' : 'white',
        cursor: 'pointer',
      }}
      {...props}>
      {props.children}
    </div>
  );
};

function FontColorButton(props) {
  const editor = useSlate();
  const [pickedColor, setPickedColor] = useState();

  return (
    <IconWrapper title="文字顏色" active={isMarkActive(editor, 'color')}>
      <label
        htmlFor="richtext-font-color"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <FormatColorTextIcon size={18} color={getFontColorValue(editor)} />
      </label>
      <input
        style={{
          opacity: 0,
          width: 0,
          height: 0,
          position: 'absolute',
          top: 0,
          right: 0,
        }}
        type="color"
        id="richtext-font-color"
        name="richtext-font-color"
        value={getFontColorValue(editor)}
        onBlur={() => {
          setFontColorValue(editor, pickedColor);
        }}
        onChange={(e) => {
          setPickedColor(e.target.value);
        }}
      />
    </IconWrapper>
  );
}

const FormatColorTextIcon = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

const isBlockPropActive = (editor, format, value = true) => {
  const it = Editor.nodes(editor, {
    match: (n) => n[format] === value,
  });

  return !!it.next().value;
};

const BlockPropButton = ({title, format, icon, value = true}) => {
  const editor = useSlate();
  const active = isBlockPropActive(editor, format, value);

  const toggle = useCallback(
    (editor, format, value) => {
      Transforms.unsetNodes(editor, format, {
        match: (n) => n[format] === value,
      });

      if (!active) {
        Transforms.setNodes(editor, {[format]: value});
      }
    },
    [active],
  );

  return (
    <IconWrapper
      title={title}
      active={active}
      onMouseDown={(event) => {
        event.preventDefault();
        toggle(editor, format, value);
      }}>
      {icon}
    </IconWrapper>
  );
};

function DbgButton(props) {
  const editor = useSlate();

  return (
    <IconWrapper
      onClick={() => {
        console.log(editor);
        console.log(JSON.stringify(editor.children, null, 2));
      }}>
      {props.icon || 'DBG'}
    </IconWrapper>
  );
}

function ClearFormatButton(props) {
  const editor = useSlate();

  const clearBlock = useCallback((editor) => {
    //not working
    Transforms.unsetNodes(editor, [], {
      mode: 'all',
      match: (n) => {
        for (const x of CUSTOM_PROPERTIES) {
          if (n.hasOwnProperty(x)) {
            return false;
          }
        }
        return true;
      },
      // CUSTOM_PROPERTIES.reduce((sum, x) => sum && !n.hasOwnProperty(x), true),
    });
  }, []);

  return (
    <IconWrapper
      onMouseDown={(event) => {
        event.preventDefault();
        clearBlock(editor);
        // clearMarks(editor);
        // clearAlignmentBlocks(editor);
      }}>
      {props.icon}
    </IconWrapper>
  );
}

export {FontColorButton, BlockPropButton, DbgButton, ClearFormatButton};
