import React, {useState} from 'react';
import * as Ant from 'antd';
import styled from 'styled-components';
import {Transforms} from 'slate';
import sanitizeHtml from 'sanitize-html';

function _sanitizeHtml(value) {
  const sanitizeOptions = {
    allowedTags: [
      'address',
      'article',
      'aside',
      'footer',
      'header',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'hgroup',
      'main',
      'nav',
      'section',
      'blockquote',
      'dd',
      'div',
      'dl',
      'dt',
      'figcaption',
      'figure',
      'hr',
      'li',
      'main',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'abbr',
      'b',
      'bdi',
      'bdo',
      'br',
      'cite',
      'code',
      'data',
      'dfn',
      'em',
      'i',
      'kbd',
      'mark',
      'q',
      'rb',
      'rp',
      'rt',
      'rtc',
      'ruby',
      's',
      'samp',
      'small',
      'span',
      'strong',
      'sub',
      'sup',
      'time',
      'u',
      'var',
      'wbr',
      'caption',
      'col',
      'colgroup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'tr',
      'img',
      'iframe',
    ],
    allowedAttributes: {
      '*': ['href', 'align', 'alt', 'center', 'bgcolor', 'style'],
      img: ['src'],
      iframe: [
        'src',
        'width',
        'height',
        'style',
        'loading',
        'allowfullscreen',
        'referrerpolicy',
        'frameborder',
        'scrolling',
        'allowtransparency',
      ],
    },
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowedIframeHostnames: [
      'www.google.com',
      'www.instagram.com',
      'www.facebook.com',
    ],
  };

  const out = sanitizeHtml(value, sanitizeOptions);
  console.log('dbg', out);
  return out;
}

function isHtml(value) {
  const re = /<(“[^”]*”|'[^’]*’|[^'”>])*>/gm;
  return re.test(value);
}

const insertHtml = (editor, value) => {
  const text = {text: ''};
  const image = {
    type: 'html',
    value: _sanitizeHtml(value),
    children: [text],
  };

  Transforms.select(editor, editor._onBlurSelection);
  Transforms.insertNodes(editor, image);
  // workaround, let user can select pure text rather than the image block
  const emptyTextNode = {
    type: 'p',
    children: [{text: ''}],
  };
  Transforms.insertNodes(editor, emptyTextNode);
};

const withHtml = (editor) => {
  const {insertData, isVoid} = editor;

  editor.isVoid = (element) => {
    return element.type === 'html' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (isHtml(text)) {
      insertHtml(editor, text);
    } else {
      insertData(data);
    }
  };
  return editor;
};

export default function HtmlModal(props) {
  const [value, setValue] = useState('');
  const {visible, onCancel, onInsert} = props;

  return (
    <Ant.Modal
      className="custom-modal"
      title="插入HTML"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        if (!isHtml(value)) {
          return Ant.message.error('需填入正確html');
        }

        onCancel();
        onInsert(value);
        setValue('');
      }}
      okText="確定"
      cancelText="取消"
      width="90%">
      <Wrapper>
        <div className="left">
          <div className="block">
            <p>✅ 支援 a (連結) 、 img (圖片)、及其他文字類、區塊類的 html</p>
          </div>
          <div className="block">
            <p>支援 iframe </p>
            <p>✅ google, facebook, instagram</p>
            <p>🚫 其餘網站暫不支援</p>
            <p>
              instagram 請勿點選官方的嵌入碼，請<b>複製連結</b>
              並將/p/後面的編號覆蓋掉以下範例中的 src 編號的區段
            </p>
            <blockquote>
              {`<iframe src="https://www.instagram.com/p/CGnw1SIhPs1/embed" width="400" height="480" frameborder="0" scrolling="no" allowtransparency="true"></iframe>`}
            </blockquote>
          </div>
          <div className="block">
            <p>🚫 為考量安全問題，不支援 script 腳本類型的 html </p>
          </div>
        </div>
        <textarea
          placeholder="填入HTML"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Wrapper>
    </Ant.Modal>
  );
}

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: stretch;
  gap: 10px;

  & > textarea {
    flex: 1;
    width: 100%;
    min-height: 300px;
    border: solid 1px #eee;
    outline: solid 1px #e5f6fd;
    padding: 5px 8px;
  }

  & > .left {
    flex: 1;

    & > .block {
      background-color: #eee;
      border-radius: 6px;
      padding: 5px 8px;
      margin-bottom: 10px;

      & > blockquote {
        padding: 3px 8px;
        font-size: 12px;
        color: #707070;
        font-family: 'Courier New', monospace;
        word-break: break-all;
      }
    }
  }
`;

export {insertHtml, withHtml};
