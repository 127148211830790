import React, {useCallback, useState} from 'react';
import {Modal, Input, Divider, Tag} from 'antd';
import {useSlate} from 'slate-react';
import {Transforms} from 'slate';

export default function VideoModal(props) {
  const [url, setUrl] = useState('');
  const [videoId, setVideoId] = useState('');
  const [error, setError] = useState('');
  const [isShort, setIsShort] = useState(false);
  const editor = useSlate();
  const {visible, onCancel} = props;

  const parseId = useCallback((url) => {
    let match;
    const shortReg = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(shorts\/))([^\/#&?]*).*/;
    if (shortReg.test(url)) {
      match = url.match(shortReg);
      setIsShort(true);
      return match && match[6].length === 11 ? match[6] : '';
    }

    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    match = url.match(regExp);
    setIsShort(false);
    return match && match[7].length === 11 ? match[7] : '';
  }, []);

  function insertYoutubeVideo(videoId) {
    const text = {text: ''};
    const video = {
      type: 'youtube',
      src: `https://www.youtube.com/embed/${videoId}`,
      children: [text],
      ...(isShort
        ? {
            short: true,
            width: 315,
            height: 560,
            display: 'block',
            position: 'static',
          }
        : {}),
    };
    Transforms.select(editor, editor._onBlurSelection);
    Transforms.insertNodes(editor, video);
    // workaround, let user can select pure text rather than the image block
    const emptyTextNode = {
      type: 'p',
      children: [{text: ''}],
    };
    Transforms.insertNodes(editor, emptyTextNode);
  }

  return (
    <Modal
      className="custom-modal"
      title="新增Youtube影片"
      visible={visible}
      bodyStyle={{padding: 20}}
      onCancel={onCancel}
      onOk={() => {
        onCancel();
        insertYoutubeVideo(videoId);
        setVideoId('');
        setUrl('');
        setError('');
      }}
      okText="確定"
      cancelText="取消">
      <label>完整網址</label>
      <Input
        placeholder="填入完整url: https://www.youtube.com/watch?v=123...."
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        onBlur={() => setVideoId(parseId(url))}
        style={{width: '100%', marginBottom: 10}}
      />
      <code style={{backgroundColor: '#eee'}}>
        {isShort
          ? 'https://www.youtube.com/shorts/'
          : 'https://www.youtube.com/watch?v='}
      </code>
      <code style={{backgroundColor: '#ddd'}}>{videoId}</code>
      <div style={{color: 'red'}}>{error}</div>
    </Modal>
  );
}
