import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {genExcelFile} from '../../Utils/ExportUtil';
import * as AllExports from '../../twpaa-export';

const ExportConfig = {
  collection: 'user_profile',
  title: '會員資料匯出',
  downloadTitle: '會員資料',
  genExcelFile: genExcelFile.bind(null, AllExports.UserProfile.exportSpec),
};

export default function TwpaaExportUserButton(props) {
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  return (
    <>
      <Ant.Button
        onClick={() => {
          setModalData({visible: true});
        }}>
        匯出
      </Ant.Button>
      <TwpaaExportUserModal
        close={() => setModalData({visible: false})}
        {...modalData}
      />
    </>
  );
}

function TwpaaExportUserModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [records, setRecords] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [status, setStatus] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {}

    if (keyword) {
      fetchData();
    }
  }, [keyword]);

  return (
    <Wrapper>
      <h2 style={{marginBottom: 30}}>{ExportConfig.title}</h2>

      <div style={{marginBottom: 10}}>
        <label style={{marginRight: 10}}>會員狀態</label>
        <Ant.Select style={{width: 100}} onChange={setStatus} value={status}>
          {[
            {value: '', name: '不指定'},
            {value: 'pending', name: '申請中'},
            {value: 'reviewing', name: '審核中'},
            {value: 'valid', name: '有效'},
            {value: 'invalid', name: '停權'},
            {value: 'withdraw_reviewing', name: '退會申請中'},
            {value: 'withdraw', name: '退會'},
          ].map((it) => {
            return (
              <Ant.Select.Option value={it.value}>{it.name}</Ant.Select.Option>
            );
          })}
        </Ant.Select>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
        <label>關鍵字</label>
        <Ant.Input
          style={{flex: 1}}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Ant.Button
          onClick={async () => {
            const [err, resp] = await AppActions.tryError(() => {
              const extraQueries = {};
              if (status) {
                extraQueries.status = status;
              }
              if (keyword) {
                extraQueries['$or'] = [
                  'name',
                  'name_eng',
                  'id_card_number',
                  'birthday',
                  'phone',
                  'email',
                  'contact_address.detail',
                  'certificate_number',
                  'chpatent',
                  'national_examination_year',
                  'agency_address.detail',
                  'agency_telephone',
                  'receipt_name',
                  'business_id_number',
                ].map((it) => {
                  return {[it]: {$regex: keyword}};
                });
              }
              return JStorage.fetchAllDocuments(
                ExportConfig.collection,
                extraQueries,
              );
            });
            if (err) {
              Ant.message.warn('API Error: ' + err);
            } else {
              if (resp.length > 0) {
                setRecords(resp);
                setDownloadUrl(
                  await ExportConfig.genExcelFile(
                    resp.map((it) => {
                      return {
                        ...it,
                        member_id: ('0000' + it?.member_id).slice(-4),
                      };
                    }),
                  ),
                );
              } else {
                Ant.message.info('查無資料');
                setRecords([]);
                setDownloadUrl(null);
              }
            }
          }}>
          確認
        </Ant.Button>
        <Ant.Button
          type="link"
          onClick={() => {
            setStatus('');
            setKeyword('');
            setRecords([]);
            setDownloadUrl(null);
          }}>
          清除
        </Ant.Button>
      </div>

      {records.length > 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 20,
          }}>
          <div>共 {records.length} 筆紀錄符合</div>
          {downloadUrl && (
            <Ant.Button
              type="link"
              target="_blank"
              download={`${ExportConfig.downloadTitle}.xlsx`}
              href={downloadUrl}>
              下載
            </Ant.Button>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
