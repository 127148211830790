import React, {useState} from 'react';
import * as Ant from 'antd';
import {Add} from '@styled-icons/material/Add';
import styled from 'styled-components';
import {Transforms} from 'slate';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import DataJson from '../../../data.json';

function isHttpUrl(src) {
  // only check if the url starts with http, because not all image url uses
  // file extension as postfix
  const re = /^https?:\/\/.*/g;
  return re.test(src);
}

const UPLOAD_DATA = {url: null, data: null, expected: ''};

const insertImage = (editor, url, options) => {
  const text = {text: ''};
  const image = {
    type: 'img',
    src: url,
    children: [text],
    maxWidth: '100%',
    ...options,
  };

  Transforms.select(editor, editor._onBlurSelection);

  let hyperLink = options?.hypeLinkUrl || undefined;
  if (hyperLink) {
    const link = {
      type: 'a',
      href: hyperLink,
      children: [],
    };

    Transforms.wrapNodes(editor, link, {
      split: true,
    });
  }

  Transforms.insertNodes(editor, image);

  // workaround, let user can select pure text rather than the image block
  const emptyTextNode = {
    type: 'p',
    children: [{text: ''}],
  };
  Transforms.insertNodes(editor, emptyTextNode);
};

const withImages = (editor) => {
  const {insertData, isVoid, isInline} = editor;

  editor.isVoid = (element) => {
    return element.type === 'img' ? true : isVoid(element);
  };

  editor.isInline = (element) => {
    return element.type === 'img' && element.hypeLinkUrl
      ? true
      : isInline(element);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (isHttpUrl(text)) {
      insertImage(editor, text, {width: '100%'});
    } else {
      insertData(data);
    }
  };
  return editor;
};

export default function ImageModal(props) {
  const [imageUrl, setImageUrl] = useState(props.url || '');
  const [uploadData, setUploadData] = useState(UPLOAD_DATA);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});

  const {visible, onCancel, onInsert, inContent, token} = props;

  return (
    <Ant.Modal
      className="custom-modal"
      title="新增圖片連結"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        if (!isHttpUrl(imageUrl)) {
          return alert('這不是圖片網址');
        }

        let hyperLink = options?.hypeLinkUrl || undefined;
        if (hyperLink) {
          if (!isHttpUrl(hyperLink) && hyperLink.indexOf('/') !== 0) {
            return alert('這不是有效的圖片點擊連結');
          }
        }

        onCancel();
        onInsert(imageUrl, options);
        setImageUrl('');
        setUploadData('');
      }}
      okText="確定"
      cancelText="取消">
      <Wrapper>
        <Ant.Upload
          className="image-uploader"
          accept="image/*"
          action={uploadData.url}
          data={uploadData.data}
          beforeUpload={async (file) => {
            async function getUploadUrlFromFile(file, options = {}) {
              const fileKey = file.name.split('.')[0];
              const fileType = file.type;
              const {acl = 'public-read', token} = options;

              return await ApiUtil.req(
                `${DataJson.storageHost}/storage/presigned/url?client_id=${
                  DataJson.clientId
                }${token ? '&token=' + token : ''}`,
                {
                  method: 'POST',
                  data: {
                    acl,
                    'Content-Type': fileType,
                    key: `${fileKey}`,
                    unique_key:
                      typeof options.unique_key === 'boolean'
                        ? options.unique_key
                        : true,
                  },
                },
              );
            }

            setLoading(true);
            try {
              let resp = await getUploadUrlFromFile(file, {token});
              setUploadData({
                url: resp.url,
                data: resp.fields,
                expected: resp.expected,
              });
            } catch (err) {
              setLoading(false);
              Ant.message.warning('無法取得上傳路徑');
            }
          }}
          showUploadList={false}
          disabled={loading}
          onChange={async (info) => {
            if (info.file.status === 'uploading') {
              // do progressing
              return;
            }
            if (info.file.status === 'done') {
              setLoading(false);
              setImageUrl(uploadData.expected);
              return;
            }
          }}>
          <UploadWrapper>
            {loading ? (
              <div className="spinner" />
            ) : imageUrl ? (
              // <img className="image" src={imageUrl}/>
              <Image src={imageUrl} />
            ) : (
              <div className="empty">
                <Add size={18} color="#999" />
              </div>
            )}
          </UploadWrapper>
        </Ant.Upload>
        <Ant.Divider>OR</Ant.Divider>
        <div style={{color: 'gray'}}>填入圖片連結</div>
        <Ant.Input
          value={imageUrl}
          disabled={loading}
          onChange={(e) => setImageUrl(e.target.value)}
          style={{
            display: 'block',
            width: '100%',
            marginTop: 5,
            marginBottom: 20,
          }}
        />

        <Ant.Divider></Ant.Divider>
        {inContent && (
          <Ant.Radio.Group
            defaultValue="original"
            onChange={(e) =>
              setOptions({
                ...options,
                width: e.target.value === 'original' ? undefined : '100%',
              })
            }
            style={{marginBottom: 20}}>
            <Ant.Radio key="original" value="original">
              原始大小
            </Ant.Radio>
            <Ant.Radio key="max-width" value="max-width">
              最大寬度
            </Ant.Radio>
          </Ant.Radio.Group>
        )}
        <div style={{color: 'gray'}}>填入圖片點擊連結</div>
        <Ant.Input
          value={options.hypeLinkUrl}
          disabled={loading}
          onChange={(e) =>
            setOptions({...options, hypeLinkUrl: e.target.value})
          }
          style={{
            display: 'block',
            width: '100%',
            marginTop: 5,
            marginBottom: 20,
          }}
        />
      </Wrapper>
    </Ant.Modal>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  & > .image-uploader {
    margin: 0 auto;
  }
`;

const UploadWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #eee;
  & > .empty {
  }
  & > .spinner {
    border: 3px solid white;
    border-top-color: lightgray;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${(props) => props.src}');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export {insertImage, withImages};
