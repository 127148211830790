import React, {useCallback, useState, useEffect} from 'react';
import {Editable, withReact, useSlate, Slate, ReactEditor} from 'slate-react';
import {Editor, Transforms, createEditor, BaseEditor} from 'slate';
import {withHistory} from 'slate-history';
import {FormatBold} from '@styled-icons/material/FormatBold';
import {FormatItalic} from '@styled-icons/material/FormatItalic';
import {FormatUnderlined} from '@styled-icons/material/FormatUnderlined';
import {Code} from '@styled-icons/material/Code';
import {FormatQuote} from '@styled-icons/material/FormatQuote';
import {FormatListBulleted} from '@styled-icons/material/FormatListBulleted';
import {FormatListNumbered} from '@styled-icons/material/FormatListNumbered';
import {FormatStrikethrough} from '@styled-icons/material/FormatStrikethrough';
import {FormatAlignCenter} from '@styled-icons/material/FormatAlignCenter';
import {FormatAlignRight} from '@styled-icons/material/FormatAlignRight';
import {FormatAlignLeft} from '@styled-icons/material/FormatAlignLeft';
import {VideoSettings} from '@styled-icons/material/VideoSettings';
import {Image as ImageIcon} from '@styled-icons/material/Image';
import {CodeCurly} from '@styled-icons/boxicons-regular/CodeCurly';
import {Highlighter} from '@styled-icons/fa-solid/Highlighter';
import {FormatSize} from '@styled-icons/material/FormatSize';
import {Height} from '@styled-icons/material/Height';
import {FileFont} from '@styled-icons/bootstrap/FileFont';
import {Dev as DevIcon} from '@styled-icons/fa-brands/Dev';
import VideoModal from './VideoModal';
import ImageModal, {withImages, insertImage} from './ImageModal';
import HtmlModal, {withHtml, insertHtml} from './HtmlModal';
import {FontColorButton, BlockPropButton, DbgButton} from './CustomButton';
import HyperLinkButton, {withLinks} from './HyperLinkButton';
import {Element, Leaf} from '../RichTextPreview';

const IconWrapper = (props) => {
  return (
    <div
      style={{
        margin: 3,
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: props.active ? 'bold' : 'normal',
        backgroundColor: props.active ? '#eee' : 'white',
        cursor: 'pointer',
      }}
      {...props}>
      {props.children}
    </div>
  );
};

const Toolbar = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        flexWrap: 'wrap',
        marginBottom: 10,
        borderBottom: 'solid 1px #eee',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: '#fff',
      }}>
      {props.children}
    </div>
  );
};

const LIST_TYPES = ['ol', 'ul'];
let initialValue = [
  {
    type: 'p',
    children: [{text: ''}],
  },
];

export const Provider = (props) => {
  const {children, onChange, content} = props;
  const editor = React.useMemo(
    () =>
      withLinks(withImages(withHtml(withHistory(withReact(createEditor()))))), //withLinks(),
    [],
  );

  useEffect(() => {
    Transforms.deselect(editor);
  }, [editor]);

  useEffect(() => {
    try {
      if (!content || !Array.isArray(content)) {
        onChange(initialValue);
      }
    } catch (err) {
      console.warn('RICH TEXT EDITOR initail parse error', content);
    }
  }, [content, onChange]);

  function isEmpty(content) {
    if (content && content.length === 1) {
      if (content[0].children.length === 1) {
        if (content[0].children[0].text === '') {
          return true;
        }
      }
    }
    return false;
  }

  if (!content || !editor) {
    return <div>初始化...</div>;
  }

  return (
    <Slate
      editor={editor}
      value={content}
      onChange={(value) => {
        // to fix typing chinese error
        if (isEmpty(value) && !isEmpty(content)) {
          ReactEditor.blur(editor);
        }

        onChange(value);
        // value or editor.children
      }}>
      {children}
    </Slate>
  );
};

const RichTextEditor = (props) => {
  const {token = ''} = props;
  const editor = useSlate();

  const [showVideoModal, toggleVideoModal] = useState(false);
  const [showImageModal, toggleImageModal] = useState(false);
  const [showHtmlModal, toggleHtmlModal] = useState(false);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <>
      <Toolbar>
        <MarkButton
          title="粗體"
          format="fontWeight"
          value="bolder"
          icon={<FormatBold size={16} color="black" />}
        />
        <MarkButton
          title="斜體"
          format="fontStyle"
          value="italic"
          icon={<FormatItalic size={16} color="black" />}
        />
        <MarkButton
          title="底線"
          format="textDecoration"
          value="underline"
          icon={<FormatUnderlined size={16} color="black" />}
        />
        <MarkButton
          title="刪除線"
          format="textDecoration"
          value="line-through"
          icon={<FormatStrikethrough size={16} color="black" />}
        />
        <MarkButton
          title="螢光筆"
          format="backgroundColor"
          value="yellow"
          icon={<Highlighter size={16} color="black" />}
        />
        <BlockPropButton
          title="水平置左"
          format="textAlign"
          value="left"
          icon={<FormatAlignLeft size={16} color="black" />}
        />
        <BlockPropButton
          title="水平置中"
          format="textAlign"
          value="center"
          icon={<FormatAlignCenter size={16} color="black" />}
        />
        <BlockPropButton
          title="水平置右"
          format="textAlign"
          value="right"
          icon={<FormatAlignRight size={16} color="black" />}
        />
        <HyperLinkButton />
        <BlockButton
          title="標題一"
          format="h1"
          icon={<span style={{fontSize: 16}}>H</span>}
        />
        <BlockButton
          title="標題二"
          format="h2"
          icon={<span style={{fontSize: 12}}>H</span>}
        />
        <BlockButton
          title="區塊引言"
          format="blockquote"
          icon={<FormatQuote size={16} color="black" />}
        />
        <MarkButton format="code" icon={<Code size={16} color="black" />} />
        <BlockButton
          title="有序列表"
          format="ol"
          icon={<FormatListNumbered size={16} color="black" />}
        />
        <BlockButton
          title="無序列表"
          format="ul"
          icon={<FormatListBulleted size={16} color="black" />}
        />
        <FontColorButton />

        <IconWrapper
          title="插入圖片"
          onMouseDown={(event) => {
            event.preventDefault();
            toggleImageModal(true);
          }}>
          <ImageIcon size={16} color="black" />
        </IconWrapper>
        <IconWrapper
          title="插入youtube"
          onMouseDown={(event) => {
            event.preventDefault();
            toggleVideoModal(true);
          }}>
          <VideoSettings size={16} color="black" />
        </IconWrapper>
        <IconWrapper
          title="插入html"
          onMouseDown={(event) => {
            event.preventDefault();
            toggleHtmlModal(true);
          }}>
          <CodeCurly size={16} color="black" />
        </IconWrapper>

        <SelectCustomValueButton
          title="調整字體大小"
          format="fontSize"
          dataType="number"
          values={[8, 10, 12, 16, 18, 20, 22, 24, 26, 28, 30, 32]}
          icon={<FormatSize size={16} color="black" />}
        />

        <SelectCustomValueButton
          title="調整行距"
          format="lineHeight"
          dataType="float"
          values={[0.8, 0.9, 1.0, 1.2, 1.5, 1.8, 2.0]}
          icon={<Height size={16} color="black" />}
        />
        {props?.options &&
          props.options.fontFamilies &&
          props.options.fontFamilies.length > 0 && (
            <SelectCustomValueButton
              title="調整字型"
              format="fontFamily"
              dataType="string"
              values={props.options.fontFamilies}
              icon={<FileFont size={16} color="black" />}
            />
          )}

        {/* <ClearFormatButton icon={<FormatClear size={16} color={'black'} />} /> */}
        <DbgButton icon={<DevIcon size={18} color="black" />} />
      </Toolbar>
      <div style={{overflow: 'auto', flex: 1}}>
        <Editable
          /*
          onBlur={() => {
            editor._onBlurSelection = editor.selection;
          }}
          onFocus={() => {
            editor._onBlurSelection = null;
          }}
          */
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
        />
      </div>

      <VideoModal
        visible={showVideoModal}
        onCancel={() => toggleVideoModal(false)}
      />
      <ImageModal
        visible={showImageModal}
        onCancel={() => toggleImageModal(false)}
        onInsert={async (url, options) => {
          insertImage(editor, url, options);
        }}
        inContent={true}
        token={token}
      />
      <HtmlModal
        visible={showHtmlModal}
        onCancel={() => toggleHtmlModal(false)}
        onInsert={(value) => insertHtml(editor, value)}
      />
    </>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  const nodeField = {
    type: isActive ? 'p' : isList ? 'li' : format,
  };
  // @ts-ignore
  Transforms.setNodes(editor, nodeField);

  if (!isActive && isList) {
    const block = {type: format, children: []};
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format, value = true) => {
  const isActive = isMarkActive(editor, format, value);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value);
  }
};

const isBlockActive = (editor, format) => {
  const it = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });

  return !!it.next().value;
};

const isMarkActive = (editor, format, value = true) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === value : false;
};

const BlockButton = ({title, format, icon}) => {
  const editor = useSlate();
  return (
    <IconWrapper
      title={title}
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}>
      {icon}
    </IconWrapper>
  );
};

const MarkButton = ({title, format, icon, value = true}) => {
  const editor = useSlate();
  return (
    <IconWrapper
      title={title}
      active={isMarkActive(editor, format, value)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format, value);
      }}>
      {icon}
    </IconWrapper>
  );
};

const SelectCustomValueButton = ({title, format, dataType, values, icon}) => {
  const editor = useSlate();
  const marks = Editor.marks(editor) || {};
  const value = marks[format] || '';
  const active = !!value;

  return (
    <div
      title={title}
      style={{
        position: 'relative',
        width: 30,
        height: 30,
        margin: 3,
        overflow: 'hidden',
        backgroundColor: active ? '#eee' : '#fff',
        fontWeight: active ? 'bold' : 'normal',
        cursor: 'pointer',
      }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
        {icon}
      </div>
      <select
        value={value}
        onChange={(e) => {
          let nextValue = e.target.value || '';
          if (dataType === 'number') {
            nextValue = parseInt(nextValue);
          } else if (dataType === 'float') {
            nextValue = parseFloat(nextValue);
          } else if (dataType === 'string') {
            nextValue = nextValue.toString();
          }
          toggleMark(editor, format, nextValue);
        }}
        style={{opacity: 0}}>
        <option>{title}</option>
        {values.map((value) => {
          return <option value={value}>{value}</option>;
        })}
      </select>
    </div>
  );
};

export default function RichTextEditorWrapper(props) {
  return (
    <Provider content={props.content} onChange={props.onChange}>
      <RichTextEditor token={props.token} options={props.options} />
    </Provider>
  );
}
