import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import UserJson from '../../../content/admin/user.json';

function findFieldTitle(k) {
  const field = UserJson.formSpec.schema.properties[k];
  if (field) {
    return field.title;
  }
  return k;
}

export default function TwpaaUserProfileRevisionButton(props) {
  const {size = 'medium'} = props;
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  return (
    <>
      <Ant.Button
        size={size}
        onClick={() => {
          setModalData({visible: true});
        }}>
        修改紀錄
      </Ant.Button>
      <TwpaaUserProfileRevisionModal
        close={() => setModalData({visible: false})}
        {...modalData}
        {...props}
      />
    </>
  );
}

function TwpaaUserProfileRevisionModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={960}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [records, setRecords] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const [err, resp] = await AppActions.tryError(
        JStorage.fetchAllDocuments('revision', {
          collection: 'user_profile',
          owner: props.owner,
        }),
      );
      setRecords(resp);
      /*
      if (resp) {
        // setRecords(resp);
        setRecords(
          resp.reduce((acc, it) => {
            return [
              ...acc,
              ...it.keys.map((k, idx) => {
                return {
                  ...it,
                  key: k,
                  old: it.olds[idx],
                  new: it.news[idx],
                };
              }),
            ];
          }, []),
        );
      }
        */
    }

    fetchData();
  }, [props.owner]);

  return (
    <Wrapper>
      <h2 style={{marginBottom: 30}}>修改紀錄</h2>

      <div id="twpaa-revision-modal-content" style={{marginTop: -40}}>
        <Ant.Table
          columns={[
            {
              title: '',
              key: 'detail',
              render: (_, record) => {
                return (
                  <div>
                    <h3>{new Date(record.created).toLocaleString('sv')}</h3>
                    <table className="twpaa-revision-table">
                      <tr style={{borderBottom: '1px solid #888'}}>
                        <th>欄位</th>
                        <th>舊值</th>
                        <th>新值</th>
                      </tr>
                      {record.keys.map((k, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{findFieldTitle(k)}</td>
                            <td>{JSON.stringify(record.olds[idx])}</td>
                            <td>{JSON.stringify(record.news[idx])}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                );
              },
            },
          ]}
          rowKey={'id'}
          dataSource={records}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  #twpaa-revision-modal-content table.twpaa-revision-table {
    width: 100%;
    word-break: break-word;

    border-collapse: collapse;
    th,
    td {
      border: 1px solid;
      padding: 2px 4px;
    }
    th:first-child {
      width: 150px;
    }
  }
`;
