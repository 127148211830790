import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {genExcelFile} from '../../Utils/ExportUtil';
import * as AllExports from '../../twpaa-export';

const ExportConfig = {
  collection: 'committee_member',
  title: '委員會成員匯出',
  downloadTitle: '委員會成員',
  genExcelFile: genExcelFile.bind(null, AllExports.CommitteeMember.exportSpec),
};

export default function TwpaaExportCommitteeMemberButton(props) {
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  return (
    <>
      <Ant.Button
        onClick={() => {
          setModalData({visible: true});
        }}>
        匯出
      </Ant.Button>
      <TwpaaExportModal
        close={() => setModalData({visible: false})}
        {...modalData}
      />
    </>
  );
}

function TwpaaExportModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

const Tabs = [
  {value: 'by_committee', label: '依據委員會'},
  {value: 'by_user', label: '依據會員'},
];

function ModalContent(props) {
  const [records, setRecords] = React.useState([]);
  const [keyword, setKeyword] = React.useState('');
  const [session, setSession] = React.useState(1);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState('by_committee');

  React.useEffect(() => {
    setKeyword('');
  }, [selectedTab]);

  return (
    <Wrapper>
      <h2 style={{marginBottom: 30}}>{ExportConfig.title}</h2>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 5,
          borderBottom: '1px solid #ccc',
        }}>
        {Tabs.map((tab) => {
          return (
            <Ant.Button
              type={selectedTab === tab.value ? 'primary' : 'link'}
              onClick={() => setSelectedTab(tab.value)}>
              {tab.label}
            </Ant.Button>
          );
        })}
      </div>

      {selectedTab === 'by_committee' && (
        <>
          <div style={{marginBottom: 10}}>
            <label>名稱</label>
            <Ant.Input
              style={{flex: 1}}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>

          <div style={{marginBottom: 10}}>
            <label>屆次</label>
            <Ant.Input
              style={{flex: 1}}
              type="number"
              value={session}
              onChange={(e) => setSession(e.target.value)}
            />
          </div>
        </>
      )}

      {selectedTab === 'by_user' && (
        <>
          <div style={{marginBottom: 10}}>
            <label>名稱</label>
            <Ant.Input
              style={{flex: 1}}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
        </>
      )}

      <div
        style={{display: 'flex', alignItems: 'center', gap: 10, marginTop: 20}}>
        <Ant.Button
          onClick={async () => {
            const [err, resp] = await AppActions.tryError(async () => {
              const extraQueries = {};

              if (selectedTab === 'by_committee') {
                if (keyword) {
                  extraQueries.name = {$regex: keyword};
                }

                if (session) {
                  extraQueries.session = parseInt(session, 10);
                }
              } else {
                if (!keyword) {
                  alert('尚未填寫關鍵字');
                  return [];
                } else {
                  const [theUser] = await JStorage.fetchAllDocuments(
                    'user_profile',
                    {
                      name: keyword,
                    },
                  );
                  if (!theUser) {
                    alert('查無使用者');
                    return [];
                  }
                  extraQueries.owner = theUser.owner;
                }
              }

              const results = await JStorage.aggregateDocuments(
                ExportConfig.collection,
                [
                  {
                    $match: extraQueries,
                  },
                  {
                    $sort: {date: -1},
                  },
                  {
                    $lookup: {
                      from: 'user_profile',
                      localField: 'owner',
                      foreignField: 'owner',
                      as: 'owner',
                    },
                  },
                ],
              );

              return results.map((it) => {
                return {
                  ...it,
                  _username: it.owner[0]?.name,
                  _email: it.owner[0]?.email,
                  _phone: it.owner[0]?.phone,
                  _member_id: ('0000' + it.owner[0]?.member_id).slice(-4),
                  _agency_name: it.owner[0]?.agency_name,
                };
              });
            });
            if (err) {
              Ant.message.warn('API Error: ' + err);
            } else {
              if (resp.length > 0) {
                setRecords(resp);
                setDownloadUrl(await ExportConfig.genExcelFile(resp));
              } else {
                Ant.message.info('查無資料');
                setRecords([]);
                setDownloadUrl(null);
              }
            }
          }}>
          確認
        </Ant.Button>
        <Ant.Button
          type="link"
          onClick={() => {
            setKeyword('');
            setSession(1);
            setRecords([]);
            setDownloadUrl(null);
          }}>
          清除
        </Ant.Button>
      </div>

      {records.length > 0 && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            marginTop: 20,
          }}>
          <div>共 {records.length} 筆紀錄符合</div>
          {downloadUrl && (
            <Ant.Button
              type="link"
              target="_blank"
              download={`${ExportConfig.downloadTitle}.xlsx`}
              href={downloadUrl}>
              下載
            </Ant.Button>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;
