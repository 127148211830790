import * as Ant from 'antd';
import * as AppActions from '../AppActions';

function genExportHeader(exportSpec) {
  return Object.keys(exportSpec).map((k) => {
    const field = exportSpec[k];
    return field.title;
  });
}

function genExportRow(exportSpec, context, record) {
  return Object.keys(exportSpec).map((k) => {
    const field = exportSpec[k];
    const value = record[k];

    if (typeof field.render === 'function') {
      return field.render({
        exportSpec,
        context,
        record,
        key: k,
      });
    }

    if (k.indexOf('_address') > -1) {
      if (!value) {
        return '';
      } else if (value.city === '海外') {
        return `${value?.city || ''} ${value?.detail || ''}`;
      } else {
        return `${value?.zip || ''} ${value?.city || ''}${
          value?.district || ''
        }${value?.detail || ''}`;
      }
    } else if (field.enum && field.enumNames) {
      if (field.type === 'boolean') {
        return (
          field.enumNames[field.enum.indexOf(value)] || (value ? '是' : '否')
        );
      }
      return field.enumNames[field.enum.indexOf(value)] || value;
    } else if (field.type === 'boolean') {
      return value ? '是' : '否';
    } else {
      return value;
    }
  });
}

export async function genExcelFile(exportSpec, records, context) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([
      genExportHeader(exportSpec),
      ...records.map(genExportRow.bind(null, exportSpec, context)),
    ]);
    window.XLSX.utils.book_append_sheet(wb, ws, '列表');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    Ant.message.success('成功創建下載連結');
    return objUrl;
  } catch (ex) {
    console.warn('generateExcelFile ex', ex);
  } finally {
    AppActions.setLoading(false);
  }
}
