import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import DataJson from '../../../data.json';
import {getCdnUrl} from '../../Utils/CdnUtil';

export function ViewPrivateUploadButton(props) {
  const {title, s3Key, size = 'medium'} = props;
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div>
      <Ant.Button
        size={size}
        disabled={!s3Key}
        onClick={() => setShowModal(true)}>
        {title}
      </Ant.Button>
      <ViewPrivateUploadModal
        visible={showModal}
        setVisible={setShowModal}
        {...props}
      />
    </div>
  );
}

export default function ViewPrivateUploadModal(props) {
  const {visible, setVisible} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={1024}
      visible={!!visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}>
      <ModalContent {...props} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [user] = useOutlet('user');
  const {s3Key} = props;
  const [secureUrl, setSecureUrl] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const {url} = await ApiUtil.req(
          `${DataJson.apiHost}/twpaa/private_upload/access?token=${user.token}&s3_key=${s3Key}`,
        );
        setSecureUrl(URL.createObjectURL(await (await fetch(url)).blob()));
      } catch (ex) {
        console.warn('REV_DBG', ex);
      }

      AppActions.setLoading(false);
    }

    fetchData();
  }, [s3Key, user]);

  return (
    <Wrapper>
      <h2>查看受保護的上傳資料</h2>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div>{s3Key}</div>
        {secureUrl && (
          <Ant.Button
            type="link"
            size="small"
            style={{marginLeft: 15}}
            onClick={() => {
              window.open(secureUrl, '_blank');
            }}>
            於新分頁開啟
          </Ant.Button>
        )}
      </div>
    </Wrapper>
  );
}
// <img src={secureUrl} alt="secure-upload" style={{marginTop: 10}} />;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;

  img.secure {
    width: 100%;
    object-fit: contain;
    border: 1px dashed #ccc;
  }
`;
