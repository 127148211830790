import React from 'react';
import {useSlate} from 'slate-react';
import {Link} from '@styled-icons/material/Link';
import {Transforms, Editor, Range, Element} from 'slate';

const isUrl = (url) => {
  const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return re.test(url);
};

const isActive = (editor) => {
  const it = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === 'a',
  });

  return !!it.next().value;
};

const withLinks = (editor) => {
  const {insertData, insertText, isInline} = editor;

  editor.isInline = (element) => {
    return element.type === 'a' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const wrapLink = (editor, url) => {
  const link = {
    type: 'a',
    href: url,
    children: [],
  };

  Transforms.wrapNodes(editor, link, {split: true});
  Transforms.collapse(editor, {edge: 'end'});
};

export default function HyperLinkButton() {
  const editor = useSlate();
  const active = isActive(editor);

  return (
    <div
      title="超連結"
      style={{
        margin: 3,
        width: 30,
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: active ? 'bold' : 'normal',
        backgroundColor: active ? '#eee' : 'white',
      }}
      onMouseDown={(event) => {
        event.preventDefault();
        if (active) {
          if (window.confirm('確定要移除連結')) {
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) && Element.isElement(n) && n.type === 'a',
            });
          }
        } else {
          const isCollapsed =
            editor.selection && Range.isCollapsed(editor.selection);
          if (isCollapsed) {
            return alert('請選擇一段文字');
          }
          const url = window.prompt('Enter the URL of the link:');
          if (!url) {
            return;
          }
          insertLink(editor, url);
        }
      }}>
      <Link size={16} color="black" />
    </div>
  );
}

export {withLinks};
