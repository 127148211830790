import React from 'react';
import * as Ant from 'antd';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import AdminLinkButton from './AdminLinkButton';
import * as CatUtil from '../../Utils/CatUtil';
import qs from 'query-string';
import TwpaaExportUserButton from '../../Components/TwpaaExportUserButton';
import TwpaaExportCommitteeMemberButton from '../../Components/TwpaaExportCommitteeMemberButton';
import TwpaaExportSupervisorMemberButton from '../../Components/TwpaaExportSupervisorMemberButton';

export function handleExtra({path, pageContext}, props) {
  if (path.indexOf('/admin/users') > -1) {
    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex', gap: 10}}>
          <TwpaaExportUserButton />
        </div>
      );
    };
  }

  if (path.indexOf('/admin/committee_member_applications') > -1) {
    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex', gap: 10}}>
          <Ant.Button
            onClick={() => {
              window.open('/admin/committee_members/?action=create', '_blank');
            }}>
            創建委員會成員
          </Ant.Button>
        </div>
      );
    };
  }

  if (path.indexOf('/admin/committee_members') > -1) {
    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex', gap: 10}}>
          <Ant.Button
            onClick={() =>
              AppActions.navigate('/admin/committee_members/?action=create')
            }>
            +
          </Ant.Button>
          <TwpaaExportCommitteeMemberButton />
        </div>
      );
    };
  }

  if (path.indexOf('/admin/supervisor_members') > -1) {
    pageContext.resource.renderCreateButton = (record, clientCallbacks) => {
      return (
        <div style={{display: 'flex', gap: 10}}>
          <Ant.Button
            onClick={() =>
              AppActions.navigate('/admin/supervisor_members/?action=create')
            }>
            +
          </Ant.Button>
          <TwpaaExportSupervisorMemberButton />
        </div>
      );
    };
  }

  const {ext_res_def} = qs.parse(props.location.search);
  if (ext_res_def) {
    try {
      const extResDefs = JSON.parse(decodeURIComponent(ext_res_def));
      for (const resKey in extResDefs) {
        if (extResDefs[resKey] !== undefined) {
          pageContext.resource[resKey] = extResDefs[resKey];
        }
      }
    } catch (err) {}
  }
}
