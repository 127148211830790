import React from 'react';
import styled from 'styled-components';
import {ArrowRight} from '@styled-icons/bootstrap/ArrowRight';
import * as AppActions from '../../AppActions';

export function renderCategoryOption(key) {
  if (key === 'option_1') {
    return <li style={{backgroundColor: '#6999C7', color: 'white'}}>獎</li>;
  } else if (key === 'option_2') {
    return <li style={{backgroundColor: '#FF6F6F', color: 'white'}}>職</li>;
  } else if (key === 'option_3') {
    return <li style={{backgroundColor: '#FFCB7A', color: 'white'}}>訴</li>;
  } else if (key === 'option_4') {
    return <li style={{backgroundColor: '#84C3B7', color: 'white'}}>商</li>;
  }
}

export function getDisableLabels(course) {
  const today = new Date().toLocaleDateString('sv');
  const resp = [];

  if (today < course.open_date) {
    resp.push('報名尚未開始');
  }
  if (today >= course.close_date) {
    resp.push('報名已截止');
  }
  if (course.reg_cnt >= course.max_reg_cnt) {
    resp.push('報名已額滿');
  }

  return resp;
}

export function getDateLabel(course) {
  if (!course.date) {
    return '---';
  }
  const dt = new Date(course.date);
  return `${course.date} (${'日一二三四五六'[dt.getDay()]})`;
}

export default function CourseItem(props) {
  const {course, onClick} = props;
  const disableLabels = getDisableLabels(course);
  const childDisableLabels = course._child_course
    ? getDisableLabels(course._child_course)
    : [];

  return (
    <Wrapper>
      <div className="date">{getDateLabel(course)}</div>
      <div className="title">{course.name}</div>
      <div className="info">
        <div>
          <label>時數</label>
          <div>
            <div>{course.hours || 0} 小時</div>
          </div>
        </div>
        <div>
          <label>時數類別</label>
          <ul>
            {course.hours_catagory?.option_1 &&
              renderCategoryOption('option_1')}
            {course.hours_catagory?.option_2 &&
              renderCategoryOption('option_2')}
            {course.hours_catagory?.option_3 &&
              renderCategoryOption('option_3')}
            {course.hours_catagory?.option_4 &&
              renderCategoryOption('option_4')}
          </ul>
        </div>
        <div>
          <label>報名費用</label>

          {course.old_event_id ? (
            <div>
              <div>會員：{course.price}</div>
              <div>非會員：{course.price_non_member}</div>
            </div>
          ) : (
            <div>
              {course.join_member && <div>會員：{course.price}</div>}
              {course.join_non_member && (
                <div>非會員：{course.price_non_member}</div>
              )}
            </div>
          )}
        </div>
      </div>

      {Array.isArray(course._course_enroll_options) ? (
        <>
          <div
            className="actions"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <button onClick={onClick}>
              <ArrowRight size={18} style={{paddingBottom: 2}} />
              {course._course_enroll_options[0].label}課程詳情
            </button>
            {disableLabels.length > 0 && (
              <div style={{color: '#aaa', textAlign: 'right', marginLeft: 6}}>
                ({disableLabels[0]})
              </div>
            )}
          </div>
          <div
            className="actions"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <button
              onClick={() => {
                AppActions.navigate(`/product/?id=${course._child_course.id}`);
              }}>
              <ArrowRight size={18} style={{paddingBottom: 2}} />
              {course._course_enroll_options[1].label}課程詳情
            </button>
            {disableLabels.length > 0 && (
              <div style={{color: '#aaa', textAlign: 'right', marginLeft: 6}}>
                ({childDisableLabels[0]})
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="actions"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <button onClick={onClick}>
            <ArrowRight size={18} style={{paddingBottom: 2}} />
            查看詳情
          </button>
          {disableLabels.length > 0 && (
            <div style={{color: '#aaa', textAlign: 'right', marginLeft: 6}}>
              ({disableLabels[0]})
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: white;
  transition: all 200ms;
  &:hover {
    box-shadow: 0px 8px 12px rgba(0,0,0,0.3);
  }

  .date {
    color: #043186;
  }

  .title {
    margin-top: 12px;
    font-weight: bold;
    min-height: 100px;
  }

  .info {
    margin: 12px 0;
    border-top: 1px solid #ddd;
    padding: 12px 0;
    border-bottom: 1px solid #ddd;

    & > * {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      & > label {
        width: 80px;
        color: #043186;
      }

      & > ul {
        display: flex;
        gap: 6px;
        list-style-type: none;
        padding: 0;
        margin: 0;

        & > li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 26px;
          height: 26px;
          border-radius: 13px;
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content; flex-end;
    button {
      padding: 0;
      border: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: transparent;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: #043186;
        font-weight: bold;
      }
    }
  }
`;
