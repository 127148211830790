import React from 'react';
import * as Ant from 'antd';
import DataJson from '../../../data.json';
import * as AppActions from '../../AppActions';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import {getOutlet, useOutlet} from 'reconnect.js';

async function uploadFile({file, name, onSuccess, prefix}) {
  function genS3KeyPath(prefix = 'private_uploads') {
    let randstr = '';
    let datestr = '';
    try {
      randstr = ('000000' + Math.floor(Math.random() * 1000000)).slice(-6);
      datestr = new Date().toLocaleString('sv').split(' ')[0];
    } catch (ex) {}
    return `${prefix}/${datestr}/${randstr}`;
  }

  try {
    AppActions.setLoading(true);
    const s3Key = `${genS3KeyPath(prefix)}/${name.replace(/[#+&?=]/g, '_')}`;
    const token = getOutlet('user').getValue()?.token;
    const resp = await ApiUtil.req(
      token
        ? `${DataJson.storageHost}/storage/presigned/url?token=${token}`
        : `${DataJson.storageHost}/storage/presigned/url?client_id=${DataJson.clientId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          'Content-Type': file.type,
          key: s3Key,
          unique_key: false,
          acl: 'private',
        },
      },
    );

    const {expected, fields, url} = resp;
    const formData = new FormData();

    for (const key in fields) {
      formData.set(key, fields[key]);
    }
    formData.set('file', file);

    const uploadResp = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (uploadResp.status.toString()[0] !== '2') {
      throw uploadResp;
    }

    await onSuccess({s3Url: expected, s3Key});
  } catch (ex) {
    console.warn(ex);
    alert('上傳失敗: 請稍後再試');
  } finally {
    AppActions.setLoading(false);
  }
}

function SelectFileModal(props) {
  const {modalData, setModalData, uiSchema} = props;
  const visible = !!modalData;

  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      open={!!visible}
      onOk={() => {
        setModalData(null);
      }}
      onCancel={() => {
        setModalData(null);
      }}
      destroyOnClose={true}>
      {visible && (
        <ModalContent
          modalData={modalData}
          setModalData={setModalData}
          uiSchema={uiSchema}
        />
      )}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {modalData, setModalData, uiSchema} = props;
  const fileInputRef = React.useRef();
  const [filename, setFilename] = React.useState(null);
  const [objUrl, setObjUrl] = React.useState(null);

  return (
    <div
      style={{
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      {uiSchema['rev:preview'] && objUrl && (
        <img
          src={objUrl}
          alt="selected-file"
          style={{
            width: '256px',
            objectFit: 'contain',
            border: '1px dashed #ccc',
          }}
        />
      )}
      <div className="controls">
        <div>
          <label
            htmlFor="private-file-upload-widget-input"
            style={{
              backgroundColor: 'white',
              border: '1px solid #888',
              display: 'block',
              width: '100%',
              textAlign: 'center',
              fontSize: 14,
              color: '#888',
              padding: 4,
              borderRadius: 2,
            }}>
            選擇檔案 {filename ? filename : ''}
          </label>
          <input
            id="private-file-upload-widget-input"
            ref={fileInputRef}
            type="file"
            style={{display: 'none'}}
            accept={uiSchema['rev:accept'] || '*'}
            onChange={() => {
              const f = fileInputRef.current.files[0];

              if (f) {
                setFilename(f.name);
                setObjUrl(URL.createObjectURL(f));
              } else {
                setFilename(null);
                setObjUrl(null);
              }
            }}
          />
        </div>

        <Ant.Button
          disabled={!filename}
          style={{width: '100%', marginTop: 8}}
          type="primary"
          onClick={() => {
            const f = fileInputRef.current.files[0];
            uploadFile({
              file: f,
              name: f.name,
              onSuccess: async ({s3Url, s3Key}) => {
                Ant.message.success('Success!');
                modalData.onChange(s3Key);
                setModalData(null);
                await AppActions.delay(500);
              },
            });
          }}>
          確認
        </Ant.Button>
      </div>
    </div>
  );
}

export default function RjsfPrivateFileUploadWidget(props) {
  const [user] = useOutlet('user');
  const [modalData, setModalData] = React.useState(null);
  const [secureUrl, setSecureUrl] = React.useState(null);

  return (
    <div>
      {props.value && (
        <div style={{marginBottom: 6}}>
          {props.value.split('/').slice(-1)[0]}
        </div>
      )}

      <div style={{display: 'flex', gap: 10}}>
        <Ant.Button
          type="primary"
          onClick={() =>
            setModalData({
              onChange: props.onChange,
            })
          }>
          選擇檔案上傳
        </Ant.Button>

        {user?.grp?.indexOf('admin') > -1 ? (
          <Ant.Button
            disabled={!props.value}
            onClick={async () => {
              AppActions.setLoading(true);
              try {
                const {url} = await ApiUtil.req(
                  `${DataJson.apiHost}/${DataJson.clientId}/private_upload/access?token=${user.token}&s3_key=${props.value}`,
                );
                const blob = await (await fetch(url)).blob();
                setSecureUrl(URL.createObjectURL(blob));
              } catch (ex) {
                console.warn('REV_DBG', ex);
              }
              AppActions.setLoading(false);
            }}>
            下載
          </Ant.Button>
        ) : (
          <div>{props.value || ''}</div>
        )}

        {secureUrl && (
          <Ant.Button
            type="link"
            href={secureUrl}
            download={props.value.split('/').slice(-1)[0]}>
            載點
          </Ant.Button>
        )}
      </div>
      <SelectFileModal
        modalData={modalData}
        setModalData={setModalData}
        uiSchema={props.uiSchema || {}}
      />
    </div>
  );
}
