import React from 'react';

export default function useAdminResourceInit({location}) {
  React.useEffect(() => {
    // trigget only once
  }, []);

  React.useEffect(() => {
    // trigger when location change
  }, [location]);

  return {
    extraElement: null,
  };
}
