import escapeHtml from 'escape-html';
import {Text} from 'slate';
import {extractStyle} from '../RichTextPreview';
import postcss from 'postcss';
const postcssJs = require('postcss-js');

async function toCss(style) {
  try {
    const result = await postcss().process(style, {parser: postcssJs});
    return result.css;
  } catch (err) {
    console.warn('convert to css error!');
  }
  return '';
}

async function serializeNode(node) {
  let style = {},
    css = '';
  if (Text.isText(node)) {
    style = extractStyle(node);
    css = await toCss(style);
    let string = escapeHtml(node.text);
    return `<span style="${css}">${string}</span>`;
  }

  const result = await Promise.all(
    node.children.map(async (n) => await serializeNode(n)),
  );
  const children = result.join('');

  style = extractStyle(node);
  css = await toCss(style);

  switch (node.type) {
    case 'blockquote':
      return `<blockquote style="${css}">${children}</blockquote>`;
    case 'h1':
      return `<h1 style="${css}">${children}</h1>`;
    case 'h2':
      return `<h2 style="${css}">${children}</h2>`;
    case 'li':
      return `<li style="${css}">${children}</li>`;
    case 'ul':
      return `<ul style="${css}">${children}</ul>`;
    case 'ol':
      return `<ol style="${css}">${children}</ol>`;
    case 'a':
      return `<a href="${escapeHtml(node.href)}">${children}</a>`;
    case 'img':
      let imageStyle = {maxWidth: style.maxWidth};
      if (style.width) {
        imageStyle.width = style.width;
      }
      let imgCss = await toCss(imageStyle);

      return `<p style="${css}"><img src="${escapeHtml(
        node.src,
      )}" alt="article image" style="${imgCss}" /><span>${children}</span></p>`;
    case 'youtube':
      return `<div style="position: relative; padding-top: ${
        (9 / 16) * 100
      }%;" ><iframe title="YouTube Player" src="${escapeHtml(
        node.src,
      )}" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;" /> ${children} </div>`;
    case 'html':
      return `<div style="display: block;"> ${node.value} </div>`;
    default:
      return `<p  style="${css}"> ${children} </p>`;
  }
}

async function serializeContent(content) {
  if (Array.isArray(content)) {
    try {
      const result = await Promise.all(
        content.map(async (n) => await serializeNode(n)),
      );

      return result.join('');
    } catch (err) {
      console.warn('fail to transform to html');
    }
  }
  return '';
}

export {serializeNode, serializeContent};
